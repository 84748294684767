import './histProgram.scss';
import React from 'react';
import { workspaceConfiguration } from 'blocklyCustom/blocklyConfig';
import {jsonToBlocklySvg} from 'components/functions/blockly/utils';
import { useTranslation } from 'react-i18next';
import Button from 'components/global/buttons';
import useWindowWidth from 'components/functions/utils/useWindowWidth';
import { formatDateTime } from 'components/functions/utils/dates';

const reorderDateString = (dateString) => {
  const [datePart, timePart] = dateString.split('T');
  const [day, month, year] = datePart.split('-');
  return `${year}-${month}-${day}T${timePart}`;
};

const ProgramCode = React.memo(({ programId, program, currentlySelectedProgramId, handleSelectProgram, onRunCommand }) => {
  const { svgData, programWorkspace } = jsonToBlocklySvg(program.program, workspaceConfiguration, false);
  const programJSON = program.program;
  const programName = program.title;
  const programDate = reorderDateString(program.updated);


  return (
    <HistProgram
      programId={programId}
      programSvgComponent={svgData}
      programJSON={programJSON}
      programName={programName}
      programDate={programDate}
      isActive={currentlySelectedProgramId === programId}
      onSelectProgram={() => handleSelectProgram(programId, programJSON)}
      onRunCommand={onRunCommand}
    />
  );
});

function HistProgram({
  programId,
  programSvgComponent,
  isActive,
  programJSON,
  programName,
  programDate,
  onSelectProgram,
  onRunCommand
}) {
  const { t } = useTranslation('programming');
  const { isMobile } = useWindowWidth();

  const handleProgramClick = () => {
    onSelectProgram(programId, programJSON);
  };

  const activeClass = isActive ? 'active' : '';

  const { formattedDate, formattedTime } = formatDateTime(programDate);
  const newProgramDate = `${formattedDate} ${formattedTime}`;

  return (
    <div className={`program-file ${activeClass}`} onClick={handleProgramClick}>
      <div className="program-thumbnail">
        {programSvgComponent}
      </div>
      <div className="program-description">
        <div className="program-name small bold">{programName}</div>
        <div className="program-date xsmall">{newProgramDate}</div>
      </div>
      {isMobile && <Button
        type="primary"
        color="green"
        iconFirst="⬆️"
        label={t('buttons.runInTinyfarm')}
        className="run-tinyfarm-btn"
        onClick={onRunCommand}
      />}
    </div>
  );
}




const ExampleProgramCode = React.memo(({ programId, program, currentlySelectedProgramId, handleSelectProgram, onRunCommand }) => {  const { svgData, programWorkspace } = jsonToBlocklySvg(program.code, workspaceConfiguration, false);
  const programJSON = program.code;
  const programName = program.title;
  const programDescription = program.description

  return (
    <ExampleProgram 
      programId={programId}
      programSvgComponent={svgData} 
      programJSON={programJSON} 
      programName={programName}
      programDescription={programDescription} 
      isActive={currentlySelectedProgramId === programId}
      onSelectProgram={() => handleSelectProgram(programId, programJSON)}
      onRunCommand={onRunCommand}
    />
  );
});


function ExampleProgram({
  programId,
  programSvgComponent,
  isActive,
  programJSON,
  programName,
  programDescription,
  onSelectProgram,
  onRunCommand
}) {
  const { t } = useTranslation('programming');
  const { isMobile } = useWindowWidth();

  const handleProgramClick = () => {
    onSelectProgram(programId, programJSON);
  };

  const activeClass = isActive ? 'active' : '';

  return (
      <div className={`program-file ${activeClass}`} onClick={handleProgramClick}>
        <div className="program-thumbnail">
          {programSvgComponent}
        </div>
        <div className="program-description">
          <div className="program-name small bold">{programName}</div>
          <div className="program-description small">{programDescription}</div>
        </div>
        {isMobile && <Button
          type="primary"
          color="green"
          iconFirst="⬆️"
          label={t('buttons.runInTinyfarm')}          
          className="run-tinyfarm-btn"
          onClick={onRunCommand} 
        />}
      </div>
    );
}

export {ProgramCode, ExampleProgramCode, HistProgram};
