import './learning.scss'
import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from 'components/pages/learning/breadCrumb'
import { HeaderElements } from 'components/pages/learning/headerElements'
import { StyledParagraph} from 'components/pages/learning/learningComponents'
import SimpleProgram from 'components/pages/programming/simpleProgram'; 
import { useTranslation } from 'react-i18next';
import QuizQuestion from 'components/pages/learning/quizQuestion';
import { BoxedIcon } from './global/boxedicon';

import LoadingSpinner from './global/loadingSpinner'

function LearningActivity() {
  useEffect(() => {
    // Scroll to the top of the page
    document.getElementById("content").scrollTo(0, 0);
  }, []);
    const { t, i18n } = useTranslation(['learning', 'global']);

    const [activity, setSingleActivity] = useState(null);

    const {number} = useParams();
    const mainLocale = i18n.language.split('-')[0];
    
    useEffect(() => {
        const fetchSingleActivity = async () => {
          try {
            const response = await fetch(
              `https://cdn.tinyfar.ms/api/activities?filters[unique_id][$eq]=${number}&locale=${mainLocale}&populate=deep`,

              {
                headers: {
                  'Authorization': `Bearer d1333c56689617833867bcf47416a66cf35d6235a9743656411dff740fa775caa3f133b2b5f3c36d953e4f67ea4ce62b9963bf674a501b6120bbb784f17bacd3c2fda1661c2181dcc2235800ae565213528d106a12af689a026df0a08e8d3b73c4da77e09cb1ce30261e42b523495e4b701923488ada79d18c85ff502bc46cfc`
                }
              }
            );
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(`Server responded with ${response.status}: ${errorData.message || response.statusText}`);
            }

            const data = await response.json();
            setSingleActivity(data.data[0]);
    
          } catch (err) {
            console.error(`Error fetching activity: ${err.message}`);
          }
        };
    
        fetchSingleActivity();
      }, []);
      
  
      return (
            <>
            { !activity ? (
                <>
                <div className="action-bar">
                  <BreadCrumb name={`${t('all_lessons')}`} />
                </div>
                <div className='main'>
                  <LoadingSpinner/>
                </div>
                </>
            ) : (
                <>
                <div className="action-bar">
                    {/* Add ": ${course.attributes.title}" when new structure is ready */}
                    <BreadCrumb name={`${t('all_lessons')} › ${t('lesson')} ${activity.attributes.unique_id} › ${t('activity')}`} />        
                    <HeaderElements page="activity" number={number} />
                </div>
                <div className="main">
                  <div className="lesson-header-wrapper gap-16 flex flex-column">
                    <BoxedIcon state="idle" icon="🤖" className="large"></BoxedIcon>
                    <div>
                      <h1 className="lesson-name">{activity.attributes.title}</h1>
                      <div className="activity-description">{activity.attributes.description}</div>
                    </div>
                  </div>
                  <div className='flex flex-col activity-content'>
                    {
                    activity.attributes.programming.map((program, index) => (
                      <div className='question flex flex-col'>
                        <React.Fragment key={index}>
                            <StyledParagraph text={program.description} />
                            <SimpleProgram programCorrectJSON={program.solution}/>
                        </React.Fragment>
                      </div>
                    ))
                    }
                  </div>
                </div>
                </>
            )}
        </>
    );
}

export default LearningActivity; 