import './farm.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FarmSVG from './frame.js';
import { takePictureDevice, getHistoricalImagesDevice, setupSocket } from 'components/functions/controls/controlsEndpointFunctions';
import { ReactComponent as CameraButton } from 'assets/controls/camerabutton.svg';
import { ReactComponent as PlayButton } from 'assets/controls/playbutton.svg';
import DateTime from 'components/global/date';

import { InfoCircleActuator } from 'components/global/infoCircle';

// redux logic
import { useSelector } from 'react-redux';
import AspectRatio from 'components/global/aspectRatio.js';
import LoadingSpinner from 'components/global/loadingSpinner.js';


function VirtualFarm() {
  const { t } = useTranslation(['controls', 'global']);
  const imageRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageDate, setImageDate] = useState(null);
  const [images, setImages] = useState([]);
  const [isFlashing, setIsFlashing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {accessToken} = useSelector(state => state.auth);

  const [containerWidth, setContainerWidth] = useState(0);
  const farmContainerRef = useRef(null);

  const colorsState = useSelector(state => state.colors);
  const {
    currentColor,
    intensity
  } = colorsState;


  useEffect(() => {
    const fetchImages = async () => {
      try {
        if (!accessToken) return;

        const result = await getHistoricalImagesDevice(accessToken, 7);
        setImages(result.images || []);

        if (result.images && result.images.length > 0) {
          setCurrentImage(result.images[0].img);
          setImageDate(result.images[0].date);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching images:', error);
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [accessToken]);


  useEffect(() => {
    if (accessToken) {
      const storedDeviceId = localStorage.getItem('deviceId');

      const socket = setupSocket(accessToken, storedDeviceId, {
        picture_data: setCurrentImage,
        picture_date: setImageDate,
      });
    }
  }, [accessToken]);
  // set flashing off if image is loaded
  useEffect(() => {
    if (currentImage) {
      setIsFlashing(false);
    }
  }, [currentImage]);

  const playTimelapse = () => {
    let currentIndex = images.length - 1;

    const interval = setInterval(() => {
      if (currentIndex < 0) {
        clearInterval(interval);
        return;
      }
      console.log(images[currentIndex].date);
      setCurrentImage(images[currentIndex].img);
      setImageDate(images[currentIndex].date);
      currentIndex--;
    }, 200);
  };

  const takePictureWithFlash = () => {
    setIsFlashing(true);
    takePictureDevice(accessToken)
      .then(() => {
        setTimeout(() => {
          getHistoricalImagesDevice(accessToken, 1)
            .then((result) => {
              if (result.images && result.images.length > 0) {
                console.log('New image:', result.images[0].date);
                const newImage = result.images[0].img;
                const newDate = result.images[0].date;
                setImages((prevImages) => [
                  { img: newImage, date: newDate },
                  ...prevImages.slice(0, -1),
                ]);
                setCurrentImage(newImage);
                setImageDate(newDate);
              } else {
                // console log error if no images
                console.log('No images');
              }
            })
            .catch((error) => {
              console.error("Error fetching latest image:", error);
              setIsFlashing(false);
            });
        }, 10000);
      })
      .catch((error) => {
        console.error("Error taking picture:", error);
        setIsFlashing(false);
      });
  };

  useEffect(() => {
    const adjustWidth = () => {
      if (farmContainerRef.current) {
        const height = farmContainerRef.current.offsetHeight;
        setContainerWidth(height);
      }
    };

    adjustWidth();

    window.addEventListener('resize', adjustWidth);

    return () => {
      window.removeEventListener('resize', adjustWidth);
    };
  }, []);


  return (
    <div className="farm-wrapper">
      <div ref={farmContainerRef} className="farm-container">
        <AspectRatio width={1} height={1} basedOn="width" />
        {isLoading || currentImage ? (
          <div className="farm-foreground flex flex-col items-center justify-between h-100 w-100">
            <LoadingSpinner />
            <DateTime date={imageDate} isLoading={isLoading} isFlashing={isFlashing} />
            {currentImage &&
              <>
                <img
                  ref={imageRef}
                  src={`data:image/jpeg;base64,${currentImage}`}
                  alt="Farm image"
                  className={`current-image current-image${isFlashing ? ' flash-on' : ''}`}
                />
                <div className={`flash${isFlashing ? ' flash-on' : ''}`}></div>
                <div className={`flex items-center justify-center gap-8 farm-buttons${isFlashing ? ' flash-on' : ''}`}>
                  <CameraButton className="farm-camera-button" onClick={takePictureWithFlash} />
                  <PlayButton className="farm-play-button" onClick={playTimelapse} /> {/* Play timelapse on click */}
                </div>
              </>
            }
            <InfoCircleActuator name='camera' keys_name='camera' icon='📷' />
          </div>
        ) : (
          <div className="farm-foreground flex flex-col items-center justify-end">
            <div className="flex flex-col text-center items-center justify-center loading pictures-empty">
              <h4>{t('farm_empty')}</h4>
              <h4>⬇️</h4>
            </div>
            <div className="flex items-center justify-center gap-8 farm-buttons">
              <CameraButton className="farm-camera-button" onClick={takePictureWithFlash} />
            </div>
          </div>
        )}
        <FarmSVG color={currentColor} intensity={intensity} />
      </div>
    </div>
  );
};

export default React.memo(VirtualFarm);  