// src/components/Login.js
import './login.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PinInput from 'components/global/pinInput'; 
import logo from 'assets/login/tinyfarms-logo.svg';
import InfoToast from 'components/global/toasts';
import { ReactComponent as EPaperDisplay } from 'assets/login/e-paper-display.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLoginMutation } from 'api/deviceApi';

const Login = () => {
  const { t } = useTranslation('global');
  const [name1, setname1] = useState('');
  const [name2, setname2] = useState('');
  const [pin, setPin] = useState(['', '', '', '']);
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [login] = useLoginMutation();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    localStorage.clear();

    const handleLoad = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const deviceName = urlParams.get('device_name');
      const devicePin = urlParams.get('device_pin');

      if (deviceName && devicePin) {
        const nameParts = deviceName.split('-');
        setname1(nameParts[0]);
        setname2(nameParts[1]);
        setPin(devicePin.split(''));

        setTimeout(() => {
          document.querySelector('.login-submit').click();
        }, 100);
      }
    };

    window.onload = handleLoad;

    return () => {
      window.onload = null;
    };
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();

    const name = `${name1}-${name2}`;
    const pinStr = pin.join('');
    try {
      await login({ name, pin: pinStr }).unwrap();
    } catch (err) {
      if (err.status === 503) {
        setToastMessage(t('toasts.login_deviceOffline'));
      } else if (err.status === 404) {
        setToastMessage(t('toasts.login_invalidCredentials'));
      } else {
        setToastMessage(t('toasts.login_error'));
      }
      setToastVisible(true);
    }
  };

  return (
    <>
      <img src={logo} alt={t('login_logoAlt')} className="login-logo" />
      <div className="login-form-wrapper">
        <h1>{t('login_connectToYourTinyfarm')}</h1>
        <form className="login-form-content" onSubmit={handleLogin}>
          <div className="login-form-top">
            <label htmlFor="name1" className="login-label">{t('login_tinyfarmName')}</label>
            <div className="login-name-inputs login-inputs">
              <input
                type="text"
                id="name1"
                value={name1}
                onChange={(e) => setname1(e.target.value.trim())}
                className="login-name-input"
                placeholder='cute'
                required
              />
              <div className="login-dash">-</div>
              <input
                type="text"
                id="name2"
                value={name2}
                onChange={(e) => setname2(e.target.value.trim())}
                className="login-name-input"
                placeholder='oyster'
                required
              />
            </div>
          </div>

          {/* Use the PinInput component here */}
          <div className="login-form-bottom">
            <label htmlFor="pin" className="login-label">{t('login_tinyfarmPin')}</label>
            <PinInput pin={pin} setPin={setPin} loading={loading} t={t} />
          </div>
          
          <button type="submit" className="login-submit" disabled={loading}>
            {t('login_connect')}
          </button>
        </form>
      </div>
      <div className="login-info-wrapper">
        <h1>{t('login_connectToYourTinyfarm')}</h1>
        <div className="login-info-text">{t('login_enterNameAndPin')}</div>
        <EPaperDisplay />
      </div>

      {/* Toasts */}
      {toastVisible && (
        <InfoToast
          emoji="⚠️"
          message={toastMessage}
          color='red'
          actions={[{ action: () => setToastVisible(false) }]}
          className='page-full'
        />
      )}
    </>
  );
};

export default Login;
