import './controlElement.scss';
import React, { useState, useEffect } from 'react';
import { PumpSlider } from './slider.js';
import { CombinedSelector, NumberInput, Dropdown } from 'components/global/selectors';
import { BoxedIcon } from 'components/global/boxedicon.js';
import { ReactComponent as DoneSvg } from 'assets/controls/done.svg';
import Button from 'components/global/buttons.js';
import { InfoCircleActuator } from 'components/global/infoCircle';
import { sendInstruction } from 'components/functions/controls/controlsEndpointFunctions';
import { useTranslation } from 'react-i18next';
import useWindowWidth from 'components/functions/utils/useWindowWidth';

// redux logic
import { useDispatch, useSelector } from 'react-redux';
import { setPumpValue, setSelectedOption } from 'redux/slices/pumpsSlice.js';

const ControlPump = ({ 
    icon, 
    name, 
    keys_name, 
    commandName, 
    min, 
    max, 
    items = [], // Ensure items is always an array
    unit, 
    id, 
    onToastChange
}) => {
    const dispatch = useDispatch(); 
    const { t } = useTranslation('controls');
    const { isMobile } = useWindowWidth();
    const accessToken = useSelector(state => state.auth.accessToken);
    const pumpValue = useSelector(state => state.pumps.pumpValues[id]);
    const selectedOptionIndex = useSelector(state => state.pumps.selectedOptions[id] ?? 0);
    const hasItems = items && items.length > 0;
    const [sliderValue, setSliderValue] = useState(pumpValue);
    const [selectedOption, setSelectedOptionState] = useState(items[selectedOptionIndex] || 0);
    const [isPumping, setIsPumping] = useState(false);
    const [showDone, setShowDone] = useState(false);

    useEffect(() => {
        if (items.length > 0) {
            const initialSelectedOption = items[Math.min(selectedOptionIndex, items.length - 1)] || items[0];
            setSelectedOptionState(initialSelectedOption);
        }
    }, [items, selectedOptionIndex]);

    useEffect(() => {
        setSliderValue(pumpValue);
    }, [pumpValue]);

    const handlePumpChange = (newValue) => {
        setSliderValue(newValue);
        dispatch(setPumpValue({ pumpId: id, value: newValue }));
    };

    const handleSliderChangeCommitted = () => {
        dispatch(setPumpValue({ pumpId: id, value: sliderValue }));
    };

    const handleDropdownChange = (option, index) => {
        setSelectedOptionState(option);
        dispatch(setSelectedOption({ pumpId: id, selectedIndex: index }));
    };

    const sendPumpInstructionWithToast = async (command, value) => {
        if (!accessToken) { 
            console.log('Access token not set. Command not sent.');
            return;
        }
        try {
            const response = await sendInstruction(command, value, accessToken);
            
            if (response.status === 200 || response.status === 204) {
                setIsPumping(true);
                
                let newPumpingDuration = value * 1.5 * 1000; 
                
                setTimeout(() => {
                    setShowDone(true);
                    setTimeout(() => {
                        setShowDone(false);
                        setIsPumping(false);
                    }, 1500);
                }, newPumpingDuration);
            } else if (response.status === 400) {
                onToastChange(true);
            }
        } catch (err) {
            onToastChange(true);
        }
    };

    const handleButtonClick = () => {
        const command = commandName + "_" + selectedOptionIndex;
        sendPumpInstructionWithToast(command, sliderValue);
    };

    const handleStopButtonClick = () => {
        const command = commandName + "_" + selectedOptionIndex;
        sendPumpInstructionWithToast(command, 0);
        setIsPumping(false);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const labelText = showDone 
    ? <><DoneSvg/>{t('button_done')}</> 
    : `${t('pumping')} ${sliderValue} ${unit} ${selectedOption ? selectedOption.label : ""}`;

    const selectors = () => {
        return (
            <div className="selectors">
                {isPumping
                ? (
                <>
                {!isMobile &&
                    <>
                        <Button
                            color="green" 
                            label={labelText}
                            onClick={handleButtonClick}
                            className="btn-pumping"
                            customStyles={{
                                animation: `fill ${isPumping ? sliderValue * 1.5 : 0}s linear forwards`
                            }}
                        />
                        {!showDone && (
                            <Button
                                color="red"
                                type="primary"
                                label={t('button_stop')}
                                onClick={handleStopButtonClick}
                                className="btn-stop"
                            />
                        )}
                    </>
                }
                </>)
                : (
                <>
                <CombinedSelector>
                    {[
                        <NumberInput key="number-input-ml" value={sliderValue} unit={unit} min={min} max={max} onChange={handlePumpChange}/>,
                        hasItems && <Dropdown key="dropdown" items={items} id={'dropdown-'+ id} onChange={handleDropdownChange} defaultIndex={Math.min(selectedOptionIndex, items.length - 1)} mobileFill />
                    ].filter(Boolean)}
                </CombinedSelector>
                {!isMobile &&
                    <Button 
                        type={isPumping ? 'secondary' : 'primary'}
                        color="green" 
                        label={t('pump')}
                        onClick={handleButtonClick} 
                        className="btn-pump"
                    />
                }
                </>
                )}
            </div>
        );
    };

    return (
        <div className="control-element">
            <div className="control-header"> 
                <BoxedIcon icon={icon} state={isPumping ? 'active' : 'inactive'} />
                <div className="control-selectors">              
                    <div className="control-title">
                        <div className="bold">{capitalizeFirstLetter(name)}</div>
                        <InfoCircleActuator name={capitalizeFirstLetter(name)} keys_name={keys_name} icon={icon}/>
                    </div>
                    {!isMobile && selectors()}
                </div>
            </div>
            {!isMobile ? (
                <PumpSlider id={id} value={sliderValue} onChange={handlePumpChange} onMouseUp={handleSliderChangeCommitted} min={min} max={max} active={isPumping} />
            ) : (
                <>
                {selectors()}
                <PumpSlider id={id} value={sliderValue} onChange={handlePumpChange} onMouseUp={handleSliderChangeCommitted} min={min} max={max} active={isPumping} />
                {isPumping
                ? <>
                    <Button 
                        color="green" 
                        label={labelText}
                        onClick={handleButtonClick}
                        className="btn-pumping"
                        customStyles={{
                            animation: `fill ${isPumping ? sliderValue * 1.5 : 0}s linear forwards`
                        }}
                    />
                    {!showDone && (
                        <Button
                            color="red"
                            label={t('stop')}
                            onClick={handleStopButtonClick}
                            className="btn-stop"
                        />
                    )}
                  </>
                : (
                    <Button 
                    type={isPumping ? 'secondary' : 'primary'}
                    color="green" 
                    label={t('pump')}
                    onClick={handleButtonClick} 
                    className="btn-pump"
                />
                )}
                </>
            )}
        </div>
    );
};

export { ControlPump };
