import './mediaViewer.scss';
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { enUS, da, lt, fr, de } from 'date-fns/locale';
import { downloadImage } from 'components/functions/gallery/imageFunctions';
import Button from 'components/global/buttons';
import { ReactComponent as Download } from 'assets/gallery/download.svg';
import { ReactComponent as ArrowLeft } from 'assets/gallery/arrow-left.svg';

// Image Viewer imports
import { ReactComponent as Delete } from 'assets/gallery/delete.svg';
import { ReactComponent as ChevronLeft } from 'assets/gallery/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/gallery/chevron-right.svg';
import { ReactComponent as Blank } from 'assets/gallery/blank.svg';

// Timelapse Viewer imports
import { ReactComponent as PlayButton } from 'assets/gallery/timelapseButton.svg';
import { ReactComponent as ProgressHandle } from 'assets/gallery/progress-handle.svg';
import { downloadTimelapse } from 'components/functions/gallery/imageFunctions';

const locales = {
  en: enUS,
  da: da,
  lt: lt,
  fr: fr,
  de: de
};

const convertToLocalDate = (dateString) => {
  const [datePart, timePart] = dateString.split('T');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour, minute, second] = timePart.split(':').map(Number);

  const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
  const localDate = new Date(utcDate.toLocaleString("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));

  return localDate;
};

const getDateLocale = (language) => {
  return locales[language] || enUS;
};

// Format date
const FormatDate = (dateString) => {
  const { i18n } = useTranslation();
  const dateLocale = getDateLocale(i18n.language);

  const localDate = convertToLocalDate(dateString);

  return format(localDate, 'dd MMM yyyy (HH:mm)', { locale: dateLocale });
};

// Image Viewer component
const ImageViewer = ({ images, currentImage, setCurrentImage, onSelectImageForDeletion, closeViewer }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation('gallery');
  
    useEffect(() => {
      const index = images.findIndex(image => `data:image/jpeg;base64,${image.img}` === currentImage);
      if (index !== -1) {
        setCurrentIndex(index);
      }
    }, [currentImage, images]);
  
    const goToPrevious = () => {
      if (currentIndex > 0) {
        const newIndex = currentIndex - 1;
        setCurrentImage(`data:image/jpeg;base64,${images[newIndex].img}`);
      }
    };
  
    const goToNext = () => {
      if (currentIndex < images.length - 1) {
        const newIndex = currentIndex + 1;
        setCurrentImage(`data:image/jpeg;base64,${images[newIndex].img}`);
      }
    };
  
    const handleDeleteClick = () => {
      const nextIndex = currentIndex === images.length - 1 ? currentIndex - 1 : currentIndex + 1;
      onSelectImageForDeletion(images[currentIndex]);
      if (images.length > 1) {
        setCurrentImage(`data:image/jpeg;base64,${images[nextIndex].img}`);
      } else {
        closeViewer();
      }
    };
  
    const handleBackgroundClick = (event) => {
      if (event.currentTarget === event.target) {
        closeViewer();
      }
    };
  
    return ReactDOM.createPortal(
      <div className="media-viewer image-viewer">
        {images.length > 0 && (
          <>
            <div className='action-bar'>
              <div className='action-bar-header'>
                <Button type="primary" color="dark" iconFirst={<ArrowLeft />} onClick={closeViewer} />
                <h4 className='text-white'>{FormatDate(images[currentIndex].date)}</h4>
              </div>
              <div className='action-buttons'>
                <Button
                  type="primary"
                  color="dark"
                  iconFirst={<Download />}
                  label={t('buttons.download')}
                  className="download-btn gallery-btn"
                  onClick={() => downloadImage(images[currentIndex])}
                  collapseWidth="mobile"
                />
                <Button
                  type="primary"
                  color="dark"
                  iconFirst={<Delete />}
                  label={t('buttons.delete')}
                  className="delete-btn gallery-btn"
                  onClick={handleDeleteClick}
                  collapseWidth="mobile"
                />
              </div>
            </div>
            <div className="media-viewer-content" onClick={handleBackgroundClick}>
              <img src={currentImage} alt={`Slide ${currentIndex}`} />
            </div>
            <div className="action-bar bottom">
              <div className="button-group">
              <Button
                  type="primary"
                  color="dark"
                  iconFirst={<ChevronLeft />}
                  onClick={goToPrevious}
                  disabled={currentIndex === 0}
                />
                <Button
                  type="primary"
                  color="dark"
                  iconFirst={<ChevronRight />}
                  onClick={goToNext}
                  disabled={currentIndex === images.length - 1}
                />
              </div>
            </div>
          </>
        )}
      </div>,
      document.body
    );
  };



// Timelapse Viewer component
const TimelapseViewer = ({ images, closeViewer }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation('gallery');

    // Progress bar
    const ProgressBar = ({ currentIndex, totalImages, onHandleChange }) => {
        const [isDragging, setIsDragging] = useState(false);
        const progressBarRef = useRef(null);
    
        const updateIndex = (clientX) => {
            const rect = progressBarRef.current.getBoundingClientRect();
            const newProgress = Math.max(0, Math.min(1, (clientX - rect.left) / rect.width));
            // Adjust the logic to snap to the middle of a segment
            const segmentLength = 1 / totalImages;
            const newIndex = Math.round((newProgress - (segmentLength / 2)) * (totalImages - 1));
            onHandleChange(Math.max(0, newIndex));
        };
    
        const handleMouseDown = (e) => {
            setIsDragging(true);
            updateIndex(e.clientX);
        };
    
        const handleMouseMove = (e) => {
            if (isDragging) {
                updateIndex(e.clientX);
            }
        };
    
        const handleMouseUp = () => {
            setIsDragging(false);
        };
    
        useEffect(() => {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
            };
        }, [isDragging]);
        
    
        return (
            <div className='progress-bar-container'>
                <PlayButton onClick={playTimelapse} className='timelapse-button'/>
                <div className="progress-bar" ref={progressBarRef} onClick={(e) => updateIndex(e.clientX)}>
                    {Array.from({ length: totalImages }, (_, index) => (
                        index < totalImages - 1 && ( // Render all segments except the last one
                            <div key={index} className={`progress-segment ${index < currentIndex ? 'filled' : ''}`}></div>
                        )
                    ))}
                    <ProgressHandle
                        className={`progress-handle ${isDragging ? 'dragging' : ''}`}
                        style={{ left: `${(currentIndex / (totalImages - 1)) * 100}%` }}
                        onMouseDown={handleMouseDown}
                    />
                </div>
            </div>
        );
    };

    // Play timelapse
    const playTimelapse = () => {
        let startTime = null;
        const duration = 300 * images.length; 
    
        const animate = (time) => {
            if (startTime === null) startTime = time;
            const timeElapsed = time - startTime;
            const newIndex = Math.min(Math.floor((timeElapsed / duration) * images.length), images.length - 1);
    
            setCurrentIndex(newIndex);
    
            if (timeElapsed < duration) {
                requestAnimationFrame(animate);
            }
        };
    
        requestAnimationFrame(animate);
    };

    // Download timelapse
    const downloadTimelapseFile = () => {
        downloadTimelapse(images);
    };

    // Handle progress
    const handleProgressChange = (newIndex) => {
        setCurrentIndex(newIndex);
    };

    return ReactDOM.createPortal(
        <div className="media-viewer timelapse-viewer">
            <div className='action-bar'>
                <div className='action-bar-header'>
                    <Button type="primary" color="dark" iconFirst={<ArrowLeft/>} onClick={closeViewer}/>
                    <h4 className='text-white'>{FormatDate(images[currentIndex].date)}</h4>
                </div>
                <Button
                    type="primary"
                    color="dark"
                    iconFirst={<Download />}
                    label={t('buttons.download')}
                    className="download-btn gallery-btn"
                    onClick={downloadTimelapseFile}
                    collapseWidth="mobile"
                />
            </div>
            <div className="media-viewer-content">
                <img src={`data:image/jpeg;base64,${images[currentIndex].img}`} alt={`Slide ${currentIndex}`} />
            </div>
            <div className="action-bar bottom">
                <ProgressBar currentIndex={currentIndex} totalImages={images.length} onHandleChange={handleProgressChange} />
            </div>
        </div>,
    document.body
    );
};

export {ImageViewer, TimelapseViewer};