// src/store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/authSlice';
import colorsReducer from './slices/colorsSlice';
import pumpsReducer from './slices/pumpsSlice';
import commandReducer from './slices/commandSlice';
import authMiddleware from './middleware/authMiddleware';
import commandMiddleware from './middleware/commandMiddleware';
import { deviceApi } from 'api/deviceApi';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'colors', 'pumps', 'command']
};

const rootReducer = combineReducers({
  auth: authReducer,
  colors: colorsReducer,
  pumps: pumpsReducer,
  command: commandReducer,
  [deviceApi.reducerPath]: deviceApi.reducer, 
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(deviceApi.middleware, authMiddleware, commandMiddleware),
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor };
