import { setLoading, logOut, setCredentials, setTokenExpired } from 'redux/slices/authSlice';
import { deviceApi } from 'api/deviceApi';
import jwtDecode from 'jwt-decode';

const isTokenExpired = (token) => {
  if (!token) return true;
  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000;
};

const isTokenCloseToExpiry = (token, threshold = 300000) => { // threshold in ms, e.g., 5 minutes
  if (!token) return true;
  const { exp } = jwtDecode(token);
  return (exp * 1000) - Date.now() < threshold;
};

const authMiddleware = store => next => async action => {
  // console.log('authMiddleware triggered:', action);
  const { auth } = store.getState();

  if (action.type === setLoading.type) {
    return next(action);
  }

  if (action.type === logOut.type || action.type === deviceApi.endpoints.logout.matchPending || action.type === deviceApi.endpoints.logout.matchFulfilled) {
    return next(action);
  }

  if (auth.loading && action.type !== setLoading.type) {
    return next(action);
  }

  if (action.type === deviceApi.endpoints.login.matchFulfilled) {
    const { access_token, refresh_token } = action.payload;
    store.dispatch(setCredentials({ accessToken: access_token, refreshToken: refresh_token }));
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    return next(action);
  }

  if (auth.accessToken && !auth.tokenExpired) {
    if (isTokenExpired(auth.accessToken)) {
      store.dispatch(setTokenExpired(true));
      store.dispatch(logOut());
      return;
    }
  }

  if (auth.tokenExpired) {
    store.dispatch(logOut());
    return;
  }

  // Refresh token if it's close to expiry
  if (auth.accessToken && isTokenCloseToExpiry(auth.accessToken)) {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      store.dispatch(setLoading(true));
      const { data, error } = await store.dispatch(deviceApi.endpoints.refreshToken.initiate({ refreshToken }));
      if (data) {
        store.dispatch(setCredentials(data));
      } else if (error) {
        store.dispatch(logOut());
      }
      store.dispatch(setLoading(false));
    }
  }

  if (!auth.isAuthenticated && !auth.loading) {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      store.dispatch(setLoading(true));
      const { data, error } = await store.dispatch(deviceApi.endpoints.refreshToken.initiate({ refreshToken }));
      if (data) {
        store.dispatch(setCredentials(data));
      } else if (error) {
        store.dispatch(logOut());
      }
      store.dispatch(setLoading(false));
    }
  }

  if (auth.loading) {
    store.dispatch(setLoading(false));
  }

  return next(action);
};

export default authMiddleware;
