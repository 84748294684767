// src/redux/slices/commandSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  success: false,
};

const commandSlice = createSlice({
  name: 'command',
  initialState,
  reducers: {
    sendCommandStart(state) {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    sendCommandSuccess(state) {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    sendCommandFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    resetCommandState(state) {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
});

export const { sendCommandStart, sendCommandSuccess, sendCommandFailure, resetCommandState } = commandSlice.actions;
export default commandSlice.reducer;
