// src/services/deviceApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from 'redux/slices/authSlice';
import config from 'config';

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.apiEndpoint}/devices`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    console.log('Sending refresh token');
    const refreshResult = await baseQuery({
      url: '/refresh',
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${api.getState().auth.refreshToken}`,
      },
    }, api, extraOptions);

    if (refreshResult?.data) {
      api.dispatch(setCredentials(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const deviceApi = createApi({
  reducerPath: 'deviceApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ name, pin }) => ({
        url: '/',
        method: 'POST',
        params: { name, pin },
      }),
    }),
    logout: builder.mutation({
      queryFn: async () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('deviceName');
        localStorage.removeItem('deviceId');
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(logOut());
        } catch (err) {
          console.error('Failed to logout:', err);
        }
      },
    }),
    refreshToken: builder.mutation({
      query: (refreshToken) => ({
        url: '/refresh',
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${refreshToken}`,
        },
      }),
    }),
    getDeviceInfo: builder.query({
      query: (accessToken) => ({
        url: '/',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      }),
    }),
    sendInstruction: builder.mutation({
      query: ({ instruction, value }) => ({
        url: '/instruction',
        method: 'POST',
        body: { instruction, value },
      }),
    }),
    getHistoricalImagesDevice: builder.query({
      query: (amount) => ({
        url: `/picture?amount=${amount}`,
        method: 'GET',
      }),
    }),
    getLatestImageDate: builder.query({
      query: () => ({
        url: '/picture?latest=true',
        method: 'GET',
      }),
    }),
    takePictureDevice: builder.mutation({
      query: () => ({
        url: '/instruction',
        method: 'POST',
        body: { instruction: 'picture', value: '1' },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRefreshTokenMutation,
  useGetDeviceInfoQuery,
  useSendInstructionMutation,
  useGetHistoricalImagesDeviceQuery,
  useGetLatestImageDateQuery,
  useTakePictureDeviceMutation,
} = deviceApi;
