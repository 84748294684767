import './chips.css';
import React from 'react';

const Chip = ({ color, secondary, iconFirst, label, iconLast, onClick, className, customStyles }) => {

  const colorClass = color ? `chip-${color}` : '';
  const secondaryClass = secondary ? `chip-secondary` : '';
  const actionClass = onClick ? `chip-action`: '';

  return (
    <>
      <div className={`chip ${colorClass} ${secondaryClass} ${className} ${actionClass}`} onClick={onClick} style={customStyles}>
        <div className="chip-content">
          {iconFirst && <span className="chip-icon-first">{iconFirst}</span>}
          <span className="chip-label">{label}</span>
          {iconLast && <span className="chip-icon-last">{iconLast}</span>}
        </div>
      </div>
    </>
  );
}

export default Chip;
