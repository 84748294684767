import "./analytics.scss";
import config from 'config'
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import SingleChart from 'components/pages/analytics/singleChart';
import {setupSocket, getInitialSensorValue} from 'components/functions/controls/controlsEndpointFunctions';
import SensorBlock from 'components/pages/controls/sensorBlock';
import { Dropdown } from './global/selectors';
import Button from 'components/global/buttons'
import { useTranslation } from 'react-i18next';
import { convertDate } from 'components/functions/utils/dates';
import { useSelector } from "react-redux";
import { AnalyticsModal } from './pages/programming/modals';

const Analytics = () => {
  
  const { t } = useTranslation(['controls', 'analytics', 'global']);
  const {accessToken} = useSelector(state => state.auth);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [timeInterval, setTimeInterval] = useState(t('timeInterval.1h')); 
  const timeIntervalToDurationMapping = {
    [t('timeInterval.1h', { ns: 'analytics' })]: '1m',
    [t('timeInterval.24h', { ns: 'analytics' })]: '15m',
    [t('timeInterval.1week', { ns: 'analytics' })]: '90m',
    [t('timeInterval.1month', { ns: 'analytics' })]: '8h'
  };
  const duration = timeIntervalToDurationMapping[timeInterval];

  const dataTypes = ['co2', 'humidity', 'temperature'];
  const [chartData, setChartData] = useState({});
  const [hoveredData, setHoveredData] = useState(null);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const handleExport = async (selectedDataTypes, fileType) => {
    // Implement the export logic here
    console.log('Exporting data for:', selectedDataTypes, 'as', fileType);
    // You might want to call an API endpoint to get the data in the selected format
  };

  const itemsAnalytics = [
    { label: t('timeInterval.1h', { ns: 'analytics' }) },
    { label: t('timeInterval.24h', { ns: 'analytics' }) },
    { label: t('timeInterval.1week', { ns: 'analytics' }) },
    { label: t('timeInterval.1month', { ns: 'analytics' }) }
  ];
  
  useEffect(() => {
    const fetchDataForDataType = async (dataType) => {
      const response = await fetch(`${config.apiEndpoint}/devices/chart_data/${dataType}/${duration}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      let data = await response.json();
      return { dataType, data };
    };
  
    const fetchData = async () => {
      if (duration) {
        const promises = dataTypes.map(dataType => fetchDataForDataType(dataType));
        const results = await Promise.allSettled(promises);
        
        const newChartData = results.reduce((acc, result) => {
          if (result.status === "fulfilled") {
            let data = result.value.data;
            acc[result.value.dataType] = data;
          }
          return acc;
        }, {});
    
        setChartData(newChartData);
    
        if (newChartData && Object.keys(newChartData).length) {
          const sortedDates = newChartData['co2'].map(item => item.x).sort();
          if (sortedDates.length) {
            setStartDate(sortedDates[0]);
            setEndDate(sortedDates[sortedDates.length - 1]);
          }
        }
      } 
    }; 
  
    fetchData();
}, [accessToken, duration]);
  

   // sensor state management. Upgrade to overall context later? 
   const [co2, setCo2] = useState(getInitialSensorValue('co2'));
   const [temperature, setTemperature] = useState(getInitialSensorValue('temperature'));
   const [humidity, setHumidity] = useState(getInitialSensorValue('humidity'));
   
   const sensorBlockMapping = useMemo(() => ({
    co2: { manualvalue: hoveredData?.co2 || co2, icon: "🌿", name: t("sensors.sensors_co2"), keys_name:"co2", unit: " ppm" },
    humidity: { manualvalue: hoveredData?.humidity || humidity, icon: "🌧️", name: t("sensors.sensors_humidity"), keys_name:"humidity", unit: "%" },
    temperature: { manualvalue: hoveredData?.temperature || temperature, icon: "🌡️", name: t("sensors.sensors_temperature"), keys_name:"temperature", unit: "°C" },
  }), [co2, humidity, temperature, hoveredData]);
  
   useEffect(() => {
     if (accessToken) {
           
       const socket = setupSocket(accessToken, {
         co2: setCo2,
         temperature: setTemperature,
         humidity: setHumidity
       });
   
       return () => {
         socket.disconnect();
       };
     }
   }, [accessToken]);


   const handleValueChange = useCallback((pointTime, pointData, dataType) => {
    if (pointTime && pointData) {
        setHoveredData({ ...hoveredData, [dataType]: pointData });
    } else {
        setHoveredData({ ...hoveredData, [dataType]: null });
    }
}, [hoveredData]);

  return (
    <>
    <div className="action-bar flex justify-between items-center gap-8">
      <Dropdown
        staticIcon="🗓"
        items={itemsAnalytics} 
        onChange={(selectedItem) => setTimeInterval(selectedItem.label)} 
        className="analytics-dropdown"
        mobileFill
      />
      <div className="flex items-center gap-16">
        <div className="analytics-date-range-wrapper">
          <h3 className="analytics-date-range">
            {!startDate ? t('loading', { ns: 'global' })
            : convertDate(startDate) === convertDate(endDate) ? convertDate(startDate)
            : `${convertDate(startDate)} – ${convertDate(endDate)}`}
          </h3>
        </div>
        <Button
          type="primary"
          color="purple"
          label={t('buttons.export', { ns: 'analytics' })}
          onClick={() => setIsExportModalOpen(true)}
          className="export-button"
        />
      </div>
    </div>
    <div id="main-content" className="main">
      {dataTypes.map(dataType => (
        <div className="chart-wrapper" key={dataType}>
          <div className="chart-header">
            <SensorBlock 
              manualvalue={sensorBlockMapping[dataType].manualvalue} 
              icon={sensorBlockMapping[dataType].icon} 
              name={sensorBlockMapping[dataType].name} 
              keys_name={sensorBlockMapping[dataType].keys_name} 
              unit={sensorBlockMapping[dataType].unit} 
            />      
          </div>    
          <SingleChart 
            dataType={dataType} 
            data={chartData[dataType]} 
            onValueChange={handleValueChange}
            id={`chart-${dataType}`}
          />
        </div>
      ))}
    </div>
    <AnalyticsModal
      isOpen={isExportModalOpen}
      onClose={() => setIsExportModalOpen(false)}
      onExport={handleExport}
      dataTypes={dataTypes}
      chartData={chartData}
    />
    </>
  );
};

export default Analytics;