import * as Blockly from 'blockly/core';
import { localizedToolboxConfiguration, workspaceConfiguration } from 'blocklyCustom/blocklyConfig';
import { xmlWorkspaceToJson, jsonToBlocklyWorkspace, jsonToBlocklySvg, returnJsonCommand, handleScrollbarVisibility } from 'components/functions/blockly/utils';
import { saveBlocklyProgram, sendJsonCommandDevice, getProgramsJSON } from 'components/functions/blockly/blocklyEndpointFunctions';
import { setupSocket } from 'components/functions/controls/controlsEndpointFunctions';
import { setupBlocks } from 'blocklyCustom/customBlocks';

export const initializeBlocklyWorkspace = (blocklyDiv, t, i18n, workspace, setWorkspace) => {
    const initializeBlockly = () => {
        setupBlocks(Blockly, t);

        if (blocklyDiv.current) {
            if (workspace) {
                workspace.dispose();
            }

            const localizedToolbox = localizedToolboxConfiguration(t);
            const workspaceRef = Blockly.inject(blocklyDiv.current, {
                ...workspaceConfiguration,
                toolbox: localizedToolbox
            });

            const workspaceChangeHandler = () => {
                try {
                    const currentWorkspaceJson = xmlWorkspaceToJson(workspaceRef);
                    if (currentWorkspaceJson) {
                        localStorage.setItem('blocklyWorkspace', JSON.stringify(currentWorkspaceJson));
                    }
                } catch (error) {
                    console.error("An error occurred while saving the workspace:", error);
                }
            };

            workspaceRef.addChangeListener(workspaceChangeHandler);

            try {
                const savedWorkspaceJson = localStorage.getItem('blocklyWorkspace');
                if (savedWorkspaceJson) {
                    jsonToBlocklyWorkspace(savedWorkspaceJson, workspaceRef);
                }
            } catch (error) {
                console.error("An error occurred while loading the workspace:", error);
            }

            Blockly.Scrollbar.scrollbarThickness = 18;

            let blocklyLanguage;
            switch (i18n.language) {
                case 'da':
                    blocklyLanguage = require('blockly/msg/da');
                    break;
                case 'lt':
                    blocklyLanguage = require('blockly/msg/lt');
                    break;
                case 'de':
                    blocklyLanguage = require('blockly/msg/de');
                    break;
                case 'fr':
                    blocklyLanguage = require('blockly/msg/fr');
                    break;
                default:
                    blocklyLanguage = require('blockly/msg/en');
            }

            Blockly.setLocale(blocklyLanguage);
            workspaceRef.scrollCenter();
            handleScrollbarVisibility(workspaceRef);
            setWorkspace(workspaceRef);

            return () => {
                workspaceRef.dispose();
                workspaceRef.removeChangeListener(workspaceChangeHandler);
            };
        }
    };

    initializeBlockly();
};

export const sendProgramCommand = async (workspace, accessToken, t, setProgramRunningJSON, setAllHistPrograms, setIsProgramRunning, setIsLocked, setIsCountdownVisible, showLockErrorToast) => {
    const currentProgramJson = xmlWorkspaceToJson(workspace);
    const jsonCommand = returnJsonCommand(workspace);
    
    try {
        const response = await sendJsonCommandDevice(jsonCommand, currentProgramJson, t('autosave'), accessToken, true);

        if (response.status === 423) { 
            showLockErrorToast();
            return;
        }

        setIsLocked(true);
        setIsCountdownVisible(true);

        setTimeout(() => {
            setIsLocked(false);
            setIsCountdownVisible(false);
        }, 10000);

        setTimeout(async () => {
            try {
                const newProgramRunningJSON = await getProgramsJSON(accessToken, 0, true);
                const allProgramsJSON = await getProgramsJSON(accessToken, 18, false);

                if (newProgramRunningJSON) {
                    setProgramRunningJSON(newProgramRunningJSON);
                    setAllHistPrograms(allProgramsJSON);
                    setIsProgramRunning(true);
                } else {
                    setIsProgramRunning(false);
                }
            } catch (error) {
                console.error('There was a problem fetching the new program:', error);
            }
        }, 1000);
    } catch (error) {
        console.error('Error running program:', error);
    }
};

export const saveProgram = async (workspace, programName, accessToken, setAllHistPrograms, setIsSaveModalOpen) => {
    const currentProgramJson = xmlWorkspaceToJson(workspace);
    
    await saveBlocklyProgram(programName, currentProgramJson, accessToken, false, false);
    
    const allProgramsJSON = await getProgramsJSON(accessToken, 18, false);
    
    setAllHistPrograms(allProgramsJSON);
    
    setIsSaveModalOpen(false); 
};

export const setupSocketConnection = (accessToken, deviceId, setLatestBlocklyStatus, setProgramRunningJSON) => {
    setupSocket(accessToken, deviceId, {
        blockly_status: setLatestBlocklyStatus,
        blockly_json: setProgramRunningJSON,
    });
};

export const generateProgramSvg = (programRunningJSON, setSvgData, setIsProgramRunning) => {
    if (programRunningJSON) {
        const { svgData: newSvgData, programWorkspace: newProgramWorkspace } = jsonToBlocklySvg(programRunningJSON, workspaceConfiguration, false);
        setSvgData(newSvgData);
        setIsProgramRunning(true);
    }
};