// ProgrammingModals.js

import React, { useState, useEffect } from 'react';
import { Modal } from 'components/global/modals';
import Button from 'components/global/buttons';
import { useTranslation } from 'react-i18next';
import PinInput from 'components/global/pinInput';
import {ProgramCode, ExampleProgramCode} from 'components/pages/programming/histProgram'; 
import { lockBlockly, unlockBlockly, getLockStatus } from 'components/functions/blockly/blocklyEndpointFunctions';
import { Dropdown, Checkbox } from 'components/global/selectors';
import { exportToXLS, exportToPDF, exportToPNG } from 'components/functions/utils/export';

export const SaveProgramModal = ({ isOpen, onClose, onSave }) => {
    const { t } = useTranslation('programming');
    const [programName, setProgramName] = useState('');
  
    const handleSaveClick = () => {
      onSave(programName);
      setProgramName(''); // Clear the input after saving
    };
  
    const handleInputChange = (e) => {
      setProgramName(e.target.value);
    };
  
    return (
      <Modal
        isOpen={isOpen}
        heading={t('modals.saveProgram.heading')}
        actions={[{ label: t('modals.saveProgram.save'), action: handleSaveClick }]}
        onClose={{ action: onClose }}
      >
        <div className="flex flex-col gap-8">
          <div className="program-input-name">{t('modals.saveProgram.programName')}</div>
          <input
            value={programName}
            onChange={handleInputChange}
            className="program-save-input"
            placeholder={t('modals.saveProgram.placeholder')}
          />
        </div>
      </Modal>
    );
  };
  export const OpenProgramModal = ({ isOpen, onClose, programs, onDuplicate }) => {
    const { t } = useTranslation('programming');
    const [selectedProgramId, setSelectedProgramId] = useState(null);
    const [selectedProgramJson, setSelectedProgramJson] = useState(null); // To store the JSON of the selected program
  
    const handleSelect = (programId, programJSON) => {
      setSelectedProgramId(programId);
      setSelectedProgramJson(programJSON); // Store the selected program's JSON
    };
  
    const handleDuplicate = () => {
      if (selectedProgramJson) {
        onDuplicate(selectedProgramJson); // Pass the selected program's JSON to the parent component
      }
    };
  
    return (
      <Modal
        large
        isOpen={isOpen}
        heading={t('modals.openProgram.heading')}
        actions={[{ label: t('modals.openProgram.open'), action: handleDuplicate }]} // Use handleDuplicate to ensure correct program is opened
        onClose={{ action: onClose }}
      >
        <div className="programs-wrapper">
          <div className="programs">
            {programs && programs.length ? (
              programs.map((program, index) => (
                <ProgramCode
                  key={index}
                  programId={index}
                  program={program} 
                  currentlySelectedProgramId={selectedProgramId} 
                  handleSelectProgram={handleSelect} // Pass program ID and JSON
                />
              ))
            ) : (
              <div className="flex flex-col text-center items-center justify-center loading">
                <div className="flex flex-col items-center justify-center">
                  <h4>{t('empty')}</h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  };
  
export const ExamplesModal = ({ isOpen, onClose, onSelect, onDuplicate }) => {
  const { t, i18n } = useTranslation('programming');
  const mainLocale = i18n.language.split('-')[0];
  const [examplePrograms, setExamplePrograms] = useState(null);
  const [currentlySelectedProgramId, setCurrentlySelectedProgramId] = useState(null);
  const [selectedProgramJson, setSelectedProgramJson] = useState(null);

  useEffect(() => {
    const fetchExamplePrograms = async () => {
      try {
        const response = await fetch(
          `https://cdn.tinyfar.ms/api/example-programs?locale=${mainLocale}&populate=deep`,
          {
            headers: {
              'Authorization': `Bearer d1333c56689617833867bcf47416a66cf35d6235a9743656411dff740fa775caa3f133b2b5f3c36d953e4f67ea4ce62b9963bf674a501b6120bbb784f17bacd3c2fda1661c2181dcc2235800ae565213528d106a12af689a026df0a08e8d3b73c4da77e09cb1ce30261e42b523495e4b701923488ada79d18c85ff502bc46cfc`
            }
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Server responded with ${response.status}: ${errorData.message || response.statusText}`);
        }

        const data = await response.json();
        setExamplePrograms(data.data);
      } catch (err) {
        console.error(`Error fetching programs: ${err.message}`);
      }
    };

    if (isOpen) {
      fetchExamplePrograms(); // Fetch only when the modal is open
    }
  }, [isOpen, t]);

  const handleSelect = (programId, programJSON) => {
    setCurrentlySelectedProgramId(programId);
    setSelectedProgramJson(programJSON);
    onSelect(programId, programJSON); // Propagate the selection upwards if needed
  };

  const handleDuplicate = () => {
    if (selectedProgramJson) {
      onDuplicate(selectedProgramJson); // Pass the selected program's JSON to the parent component
    }
  };

  return (
    <Modal
      large
      isOpen={isOpen}
      heading={t('modals.exampleProgram.heading')}
      actions={[{ label: t('modals.openProgram.open'), action: handleDuplicate }]}
      onClose={{ action: onClose }}
    >
      <div className='programs-wrapper'>
        <div className="programs">
          {examplePrograms && examplePrograms.length ? (
            examplePrograms.map((program, index) => (
              <ExampleProgramCode
                key={index}
                programId={index}
                program={program.attributes}
                currentlySelectedProgramId={currentlySelectedProgramId}
                handleSelectProgram={handleSelect} // Pass program ID and JSON
              />
            ))
          ) : (
            <div className="flex flex-col text-center items-center justify-center loading">
              <div className="flex flex-col items-center justify-center">
                <h4>{t('empty')}</h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const LockUnlockModal = ({ accessToken, isOpen, onClose, setWrongPinToast }) => {
  const { t } = useTranslation('programming');
  const [isLocked, setIsLocked] = useState(false);
  const [pin, setPin] = useState(['', '', '', '']);
  const [lockedUntil, setLockedUntil] = useState(null);
  const [lockDuration, setLockDuration] = useState(0);

  const handleLock = async () => {
    const lock_code = pin.join('');
    try {
      const response = await lockBlockly(accessToken, lockDuration, lock_code);
  
      if (response.ok) {
        setIsLocked(true);
        onClose(); // Close the modal on successful lock
      } else {
        console.error('Failed to lock Blockly:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUnlock = async () => {
    const lock_code = pin.join('');
    try {
      const response = await unlockBlockly(accessToken, lock_code);

      if (response.ok) {
        setIsLocked(false);
        onClose(); // Close the modal on successful unlock
      } else if (response.status === 423) {
        // Trigger the toast for incorrect PIN
        setWrongPinToast(true);
      } else {
        console.log('Error with PIN', response);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const checkLockStatus = async () => {
      if (isOpen) {
        const result = await getLockStatus(accessToken);
        setIsLocked(result.isLocked);
        setLockedUntil(result.lockedUntil);
      }
    };

    checkLockStatus();
  }, [isOpen, accessToken]);

  const isPinFilled = () => pin.every((digit) => digit !== '');

  const canLock = isPinFilled() && lockDuration > 0;
  const canUnlock = isPinFilled();

  return (
    <Modal
      isOpen={isOpen}
      heading={isLocked ? t('modals.unlock.heading') : t('modals.lock.heading')}
      actions={[
        {
          label: isLocked ? t('modals.unlock.action') : t('modals.lock.action'),
          action: isLocked ? handleUnlock : handleLock,
          disabled: isLocked ? !canUnlock : !canLock,
        }
      ]}
      onClose={{ action: onClose }}
    >
      <div className="flex flex-col gap-8">
        <div className="lock-info">
          {isLocked ? t('modals.unlock.info') : t('modals.lock.info')}
        </div>
        <div className="modal-form-bottom">
          <PinInput pin={pin} setPin={setPin} t={t} />
          
          {!isLocked && (
            <div className="lock-duration-wrapper flex flex-col gap-16 pt-4">
              <div className="lock-duration">{t('modals.lock.duration')}</div>
              <div className="lock-duration-buttons flex gap-8 align-center justify-center">
                <Button 
                  type="secondary" 
                  color="purple" 
                  label={t('modals.lock.duration1')} 
                  iconFirst={"⏳"} 
                  selectable 
                  selected={lockDuration === 1}  
                  onClick={() => setLockDuration(1)}
                />
                <Button 
                  type="secondary" 
                  color="purple" 
                  label={t('modals.lock.duration2')} 
                  iconFirst={"🌅"} 
                  selectable 
                  selected={lockDuration === 2}  
                  onClick={() => setLockDuration(2)}
                />
                <Button 
                  type="secondary" 
                  color="purple" 
                  label={t('modals.lock.duration3')} 
                  iconFirst={"📅"} 
                  selectable 
                  selected={lockDuration === 3}  
                  onClick={() => setLockDuration(3)}
                />
              </div>
            </div>
          )}
          {isLocked && (
          <div className="locked-until-placeholder flex flex-col gap-16 pt-4">
            <div className="locked-until-header">{t('modals.unlock.duration')}</div>
            <div className="flex items-center justify-center locked-until-date">
              {lockedUntil ? lockedUntil.replace("T", "\u00A0\u00A0") : t('modals.unlock.noLockTime')}
            </div>
          </div>
        )}
        </div>
      </div>
    </Modal>
  );
};


export const AnalyticsModal = ({ isOpen, onClose, onExport, dataTypes, chartData }) => {
  const { t } = useTranslation(['analytics', 'global']);
  const [selectedDataTypes, setSelectedDataTypes] = useState(dataTypes);
  const [fileType, setFileType] = useState(t('modals.export.fileTypes.excel'));

  const handleDataTypeToggle = (dataType) => {
    setSelectedDataTypes(prevSelected =>
      prevSelected.includes(dataType)
        ? prevSelected.filter(type => type !== dataType)
        : [...prevSelected, dataType]
    );
  };

  const handleExport = () => {
    const dataToExport = selectedDataTypes.reduce((acc, dataType) => {
      acc[dataType] = chartData[dataType];
      return acc;
    }, {});

    const elementIds = selectedDataTypes.map(dataType => `chart-${dataType}`);

    switch (fileType) {
      case t('modals.export.fileTypes.excel'):
        exportToXLS(dataToExport, 'tinyfarm_data.xlsx');
        break;
      case t('modals.export.fileTypes.pdf'):
        exportToPDF(elementIds, 'tinyfarm_data.pdf');
        break;
      case t('modals.export.fileTypes.image'):
        exportToPNG(elementIds, 'tinyfarm_data.png');
        break;
      default:
        console.error('Unsupported file type');
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      heading={t('modals.export.heading')}
      actions={[
        { label: t('buttons.export', { ns: 'analytics' }), action: handleExport, type: 'primary' }
      ]}
      onClose={{ action: onClose }}
    >
      <p className="mb-6">{t('modals.export.subtext')}</p>
      <div className="flex justify-between">
        <div className="select-sensors w-1/2 pr-4">
          <h4 className="mb-4">{t('modals.export.selectSensors')}</h4>
          <div className="flex flex-col space-y-4">
            {dataTypes.map(dataType => (
              <div key={dataType} className="flex items-center space-x-2">
                <Checkbox
                  checked={selectedDataTypes.includes(dataType)}
                  onChange={() => handleDataTypeToggle(dataType)}
                />
                <span>{t(`sensors.${dataType}`)}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="choose-file-type w-1/2 pl-4">
          <h4 className="mb-4">{t('modals.export.chooseFileType')}</h4>
          <Dropdown
            items={[
              { label: t('modals.export.fileTypes.excel') },
              { label: t('modals.export.fileTypes.pdf') },
              { label: t('modals.export.fileTypes.image') }
            ]}
            onChange={(selectedItem) => setFileType(selectedItem.label)}
            value={fileType}
          />
        </div>
      </div>
    </Modal>
  );
};