import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const availableLanguages = ['en', 'da', 'lt', 'de', 'fr'];
const languageDetectorOptions = {
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  lookupQuerystring: 'lng',
  lookupCookie: 'i18n',
  lookupLocalStorage: 'i18nLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], 

  cookieMinutes: 10,
  cookieDomain: window.location.hostname,

  htmlTag: document.documentElement,

  checkWhitelist: true,
};


const translationFiles = require.context('./translations/', true, /\.json$/);
const resources = translationFiles.keys().reduce((acc, file) => {
  const segments = file.replace('./', '').split('/');
  const namespace = segments[0];
  const languageCode = segments[1].replace('.json', '');

  if (!acc[languageCode]) acc[languageCode] = {};
  acc[languageCode][namespace] = translationFiles(file);
  return acc;
}, {});

resources.availableLanguages = availableLanguages;


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    whitelist: availableLanguages, 
    fallbackLng: 'en',
    debug: false,
    ns: ['global', 'controls','programming','analytics','learning', 'gallery'],
    defaultNS: 'global',
    backend: {
      loadPath: '/translations/{{ns}}/{{lng}}.json'
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
    detection: languageDetectorOptions,
  }, (err, t) => {
      if (err) {
        console.error('i18next initialization failed:', err);
      } else {
        // console.log('Detected language:', i18n.language); 
      }
  });

i18n.availableLanguages = availableLanguages;


export default i18n;
