import React, { useState, useEffect } from 'react';

const GridComponent = ({ items, containerRef }) => {
  const [gridKeeperCount, setGridKeeperCount] = useState(0);

  useEffect(() => {
    const updateGridInfo = () => {
      if (containerRef.current) {
        const style = window.getComputedStyle(containerRef.current);
        const gridTemplateColumns = style.getPropertyValue('grid-template-columns');
        const columns = gridTemplateColumns.split(' ').filter(Boolean).length;

        const lastRowItems = items.length % columns;
        setGridKeeperCount(lastRowItems === 0 ? 0 : columns - lastRowItems);
      }
    };

    const resizeObserver = new ResizeObserver(updateGridInfo);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    updateGridInfo(); // Initial call

    return () => resizeObserver.disconnect(); // Cleanup
  }, [items.length, containerRef]); // Include containerRef in the dependency array

  return (
    <>
      {[...Array(gridKeeperCount)].map((_, index) => (
        <div key={index} className="gallery-grid-keeper"/>
      ))}
    </>
  );
};

export default GridComponent;
