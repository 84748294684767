import './gallery.scss';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { takePictureDevice } from 'components/functions/controls/controlsEndpointFunctions';
import { getHistoricalImagesDevice, deletePicturesDevice, downloadSelectedImages } from 'components/functions/gallery/imageFunctions';
import { Dropdown } from './global/selectors';
import { useTranslation } from 'react-i18next';
import Button from './global/buttons';
import { ReactComponent as Camera } from 'assets/gallery/camera.svg';
import { ReactComponent as CheckboxPurple } from 'assets/gallery/checkbox-purple.svg';
import { ReactComponent as Download } from 'assets/gallery/download.svg';
import { ReactComponent as Timelapse } from 'assets/gallery/timelapse.svg';
import { ReactComponent as Delete } from 'assets/gallery/delete.svg';
import { ReactComponent as Cross } from 'assets/global/cross-stroke-medium.svg';
import DatePeriod from 'components/pages/gallery/datePeriod';
import { ImageViewer, TimelapseViewer } from 'components/pages/gallery/mediaViewer';
import { Modal } from './global/modals';
import { parseISO } from 'date-fns';
import LoadingSpinner from './global/loadingSpinner';
import { useSelector } from 'react-redux';

const Gallery = () => {
  useEffect(() => {
    // Scroll to the top of the page
    document.getElementById("content").scrollTo(0, 0);
  }, []);
  const { t } = useTranslation(['gallery', 'controls', 'global']);
  const {accessToken} = useSelector(state => state.auth);
  const [timeInterval, setTimeInterval] = useState(t('timeInterval.weeks'));
  const [selectedImages, setSelectedImages] = useState([]);
  const [orderedSelectedImages, setOrderedSelectedImages] = useState([]);
  const [selectedImageObjects, setSelectedImageObjects] = useState([]);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const [imageToDelete, setImageToDelete] = useState(null);
  const totalImagesLoadedRef = useRef(0);
  const isLoadingRef = useRef(false);

  // state to select a whole section

  // modals
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [isTimelapseViewerOpen, setIsTimelapseViewerOpen] = useState(false);

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(prevState => !prevState);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
  };

  const closeTimelapseViewer = () => {
    setIsTimelapseViewerOpen(false);
  };

  const itemsGallery = [
    { label: t('timeInterval.weeks') },
    { label: t('timeInterval.months') },
    { label: t('timeInterval.all_pictures') },
  ];

  const timeIntervalMapping = {
    [t('timeInterval.weeks')]: 'week',
    [t('timeInterval.months')]: 'month',
    [t('timeInterval.all_pictures')]: 'all',
  };

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setIsImageViewerOpen(true);
  };

  const handleSelectionChange = (newSelectedImages) => {
    setSelectedImages(newSelectedImages);
    const newOrderedSelectedImages = [...newSelectedImages]
      .sort((a, b) => parseISO(a.date) - parseISO(b.date))
      .map(item => ({
        img: item.img,
        date: item.date
      }));

    setOrderedSelectedImages(newOrderedSelectedImages);

  };

  const handleSingleImageDeletion = () => {
    if (imageToDelete) {
      const payload = [{ date: imageToDelete.date }];
      deletePicturesDevice(accessToken, payload)
        .then(() => {
          const updatedImages = images.filter(img => img.date !== imageToDelete.date);
          setImages(updatedImages);
          setImageToDelete(null);
          toggleDeleteModal();
        })
        .catch((error) => {
          console.error('Failed to delete image', error);
        });
    }
  };

  const selectImageForDeletion = (image) => {
    setImageToDelete(image);
    toggleDeleteModal();
  };

  const handleDeleteImages = () => {
    const remainingImages = images.filter(image =>
      !selectedImages.some(selectedImage => selectedImage.date === image.date)
    );

    setImages(remainingImages);
    setSelectedImages([]);
    setSelectedImageObjects([]);

    deletePicturesDevice(accessToken, selectedImages);
    toggleDeleteModal();
  };

  const toggleSelectMode = () => {
    if (isSelectMode) {
      setSelectedImages([]);
    }
    setIsSelectMode(!isSelectMode);
  };

  const takePicture = () => {
    takePictureDevice(accessToken);
  };

  const handleDropdownChange = (selectedItem) => {
    const interval = timeIntervalMapping[selectedItem.label] || 'all';
    setTimeInterval(interval);
  };

  const loadImages = useCallback(async () => {
    // Check if loading is not currently in progress, more images are available, and total images loaded are less than 100
    if (!isLoadingRef.current && hasMoreImages && totalImagesLoadedRef.current < 100) {
      isLoadingRef.current = true; // Indicate loading start
      try {
        const result = await getHistoricalImagesDevice(accessToken, 10, currentPage);
        if (result && result.images.length > 0) {
          // Calculate number of images to load, ensuring not to exceed the 100-image limit
          const imagesToLoad = Math.min(result.images.length, 100 - totalImagesLoadedRef.current);
          const enhancedImages = result.images.slice(0, imagesToLoad).map((img, index) => ({
            ...img,
            // Generate unique ID for each image
            id: `${totalImagesLoadedRef.current + index}`
          }));
          setImages(prev => [...prev, ...enhancedImages]);
          totalImagesLoadedRef.current += imagesToLoad; // Update the total count
          setCurrentPage(prevPage => prevPage + 1);

          // If we've reached 100 images, indicate there are no more images to load
          if (totalImagesLoadedRef.current >= 100) {
            setHasMoreImages(false);
          }
        } else {
          // No more images are available from the API
          setHasMoreImages(false);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        isLoadingRef.current = false; // Reset loading indicator
      }
    }
  }, [accessToken, currentPage, hasMoreImages]);

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  return (
    <>
      <div className="action-bar">
        {!isSelectMode ? (
          <>
            <Dropdown
              items={itemsGallery}
              onChange={handleDropdownChange}
              className="gallery-dropdown"
              staticIcon="🗓"
              mobileFill
            />
            <div className='action-buttons'>
              <Button
                type="secondary"
                color="purple"
                iconFirst={<CheckboxPurple />}
                className="select-picture-btn gallery-btn"
                onClick={toggleSelectMode}
                label={t('buttons.select_pictures')}
              />
              <Button
                type="primary"
                color="purple"
                iconFirst={<Camera />}
                label={t('buttons.take_picture')}
                className="take-picture-btn gallery-btn"
                onClick={takePicture}
              />
            </div>
          </>
        ) : (
          <>
            <div className='selection-bar-header flex items-center justify-start'>
              <Button
                iconFirst={<Cross />}
                type='secondary'
                color='purple'
                onClick={toggleSelectMode}
                className='toggle-select-close-btn'
              />
              <h4 className='selection-bar-heading'>{t('buttons.numberSelected', { count: selectedImages.length })}</h4>
            </div>
            {selectedImages.length >= 1 && (
              <div className="action-buttons flex">
                <Button
                  type="primary"
                  color="purple"
                  iconFirst={<Download />}
                  label={t('buttons.download')}
                  className="download-btn gallery-btn"
                  onClick={() => downloadSelectedImages(selectedImages, images)}
                />
                {selectedImages.length >= 2 && (
                  <Button
                    type="primary"
                    color="green"
                    iconFirst={<Timelapse />}
                    label={t('buttons.play_timelapse')}
                    className="timelapse-btn gallery-btn"
                    onClick={() => {
                      setCurrentImage(selectedImages[0]);
                      setIsTimelapseViewerOpen(true);
                    }}
                  />
                )}
                <Button
                  type="primary"
                  color="red"
                  iconFirst={<Delete />}
                  label={t('buttons.delete')}
                  className="delete-btn gallery-btn"
                  onClick={toggleDeleteModal}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className='main'>
        {isLoading ? (
          <LoadingSpinner />
        ) : images.length > 0 ? (
          <DatePeriod
            images={images}
            timeInterval={timeInterval}
            isSelectMode={isSelectMode}
            onSelectionChange={handleSelectionChange}
            onImageClick={handleImageClick}
          />
        ) : (
          // <div className="flex flex-col text-center items-center justify-center">
          // </div>
          <LoadingSpinner />
        )}
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        heading={t('modals.deleteAmount', { count: selectedImages.length || (imageToDelete ? 1 : 0) })}
        subheading={t('modals.recover')}
        actions={[{
          label: t('buttons.delete'),
          color: 'red',
          action: selectedImages.length ? handleDeleteImages : handleSingleImageDeletion,
        }]}
        onClose={{ action: toggleDeleteModal }}
      />
      {isImageViewerOpen && (
        <ImageViewer
          currentImage={currentImage}
          images={images}
          setCurrentImage={setCurrentImage}
          onSelectImageForDeletion={selectImageForDeletion}
          closeViewer={closeImageViewer}
        />
      )}
      {isTimelapseViewerOpen && (
        <TimelapseViewer
          images={orderedSelectedImages}
          closeViewer={closeTimelapseViewer}
        />
      )}
    </>
  );
};

export default Gallery;
