import '../../programming.scss';
import React, { useEffect, useRef, useState } from 'react';
import * as Blockly from 'blockly/core';
import { useSelector } from 'react-redux';
import { localizedToolboxConfiguration, workspaceConfiguration } from 'blocklyCustom/blocklyConfig';
import {  handleScrollbarVisibility, 
          xmlWorkspaceToJson, 
          returnJsonCommand} from 'components/functions/blockly/utils'
import {  sendJsonCommandDevice } from 'components/functions/blockly/blocklyEndpointFunctions'
import {setupBlocks} from 'blocklyCustom/customBlocks'
import ProgramCorrect from './placeholders/correct';
import Button from 'components/global/buttons'
import { useTranslation } from 'react-i18next';
import * as en from 'blockly/msg/en';
import * as da from 'blockly/msg/da';
 

function SimpleProgram({programCorrectJSON}) {
  const { t, i18n } = useTranslation('programming');
  const {accessToken} = useSelector(state => state.auth);
  const blocklyDiv = useRef(null);
  const [workspace, setWorkspace] = useState(null); 
  const [programRunningJSON, setProgramRunningJSON] = useState(null);
  const [isProgramRunning, setIsProgramRunning] = useState(false)
  const initializeCustomBlocks = (t) => {
    setupBlocks(Blockly, t);
  }



  async function sendCommand() {
    const currentProgramJson = xmlWorkspaceToJson(workspace);
    const jsonCommand = returnJsonCommand(workspace);
    setProgramRunningJSON(jsonCommand);
    setIsProgramRunning(true);
    sendJsonCommandDevice(jsonCommand, currentProgramJson,t('autosave'),accessToken, true);
        
  }

  useEffect(() => {
    initializeCustomBlocks(t);
  }, []);

  

  useEffect(() => {
    // blockly workspace configuration and initial state
    const localizedToolbox = localizedToolboxConfiguration(t);
    const workspaceRef = Blockly.inject(blocklyDiv.current, {
      ...workspaceConfiguration,
      toolbox: localizedToolbox
    });


    Blockly.Scrollbar.scrollbarThickness = 18; 
    let blocklyLanguage;
    switch (i18n.language) {
      case 'en':
        blocklyLanguage = en;
        break;
      case 'da':
        blocklyLanguage = da;
        break;
      default:
        blocklyLanguage = en;  
    }

    Blockly.setLocale(blocklyLanguage);   
    workspaceRef.scrollCenter();
    
    handleScrollbarVisibility(workspaceRef);
    setWorkspace(workspaceRef);
    
    // Dispose of workspace when we're done
    return () => {
      workspaceRef.dispose();
    };
  }, 
  []);
  
 
return (
  <div className="flex flex-col w-full programming-wrapper">
    <div className="action-bar">
      <div className="button-group">
        <Button type="secondary" color="purple" iconFirst="↩️" label={t('buttons.undo')} onClick={() => workspace.undo(false)} />
        <Button type="secondary" color="purple" iconFirst="↪️" label={t('buttons.redo')} onClick={() => workspace.undo(true)} />
        <Button type="secondary" color="purple" iconFirst="🗑" label={t('buttons.clear')} onClick={() => workspace.clear()} />
      </div>
      <Button
            type="primary"
            color="green"
            iconFirst="⬆️"
            label={t('buttons.runInTinyfarm')}          
            className="run-tinyfarm-btn"
            onClick={sendCommand} 
      />
    </div>
    <div className="relative flex-grow blockly-workspace-container">
    <div ref={blocklyDiv} id="blockly-div"/>
    {isProgramRunning && (
      <ProgramCorrect 
      attemptedProgramJSON={programRunningJSON}
      isProgramRunning={isProgramRunning}
      programCorrectJSON={programCorrectJSON}
      currentWorkspace={workspace}
      workspaceConfig={workspaceConfiguration}/>
      )}
    </div>
    </div>
);
}

export default SimpleProgram;
