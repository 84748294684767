import { deviceApi } from 'api/deviceApi';
import { sendCommandStart, sendCommandSuccess, sendCommandFailure } from 'redux/slices/commandSlice';

const commandMiddleware = store => next => async action => {
  if (action.type.startsWith(deviceApi.reducerPath)) {
    const { dispatch } = store;
    const { type, meta } = action;
    
    if (type.endsWith('/pending')) {
      dispatch(sendCommandStart());
    } else if (type.endsWith('/fulfilled')) {
      dispatch(sendCommandSuccess());
    } else if (type.endsWith('/rejected')) {
      dispatch(sendCommandFailure(meta.error));
    }
  }
  
  return next(action);
};

export default commandMiddleware;
