import React, {useState} from 'react';
import './settingItem.scss';
import Button from 'components/global/buttons';
import Toggle from 'components/global/toggle';
import { sendInstruction } from 'components/functions/controls/controlsEndpointFunctions' 
import { Modal } from 'components/global/modals'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { logOut } from 'redux/slices/authSlice';

export const SettingItem = ({ color, title, description, currentValue, actionType, buttonLabel, isLogout = '', isWarning = false, instruction = '' }) => {
    const { t } = useTranslation('settings');
    const {accessToken, logout} = useSelector(state => state.auth);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const dispatch = useDispatch();
    const toggleWarningModal = () => {
        setIsWarningModalOpen(prevState => !prevState);
    };

    const handleConfirm = () => {
        sendInstruction(instruction, '', accessToken);
        if (isLogout) {
            dispatch(logOut());
        } else {
            toggleWarningModal();
        } 
    };

    const handleButtonClick = () => {
        if (isWarning) {
            setIsWarningModalOpen(true);
        } else if (isLogout) {
            logout();
        } else {
            sendInstruction(instruction, '', accessToken);
        }
    };

    const handleToggleChange = (isToggleOn) => {
        console.log(`Toggle changed to ${isToggleOn ? 'ON' : 'OFF'}`);
    };

  return (
    <div className={`setting setting-${color}`}>
      <div className='setting-header'>
        <p className="setting-title bold m0">{title}</p>
        <p className="setting-value small m0">{currentValue}</p>
        {actionType && 
          <div className='setting-actions'>
            {actionType === 'button' ? (
              <Button 
                type='secondary'
                color={color || "purple"} 
                label={buttonLabel} 
                onClick={handleButtonClick} 
                small
              />
            ) : actionType === 'toggle' ? (
              <Toggle
                onChange={handleToggleChange} 
              />
            ) : null}
          </div>
        }
      </div>
      {description &&
        <p className='setting-description small m0'>
          {description}
        </p>
      }
    <Modal
        isOpen={isWarningModalOpen}
        heading={t('modals.action')}
        subheading={t('modals.action_text')}
        actions={[{label: t('modals.button_confirm'), color: "red", action: handleConfirm}]}
        onClose={{action: toggleWarningModal}}
    />
    </div>
    
  );
};
