import config from 'config';

export const getBitmap = async (accessToken, deviceName) => {

  const cachedBitmap = localStorage.getItem(`bitmap-${deviceName}`);
  
    if (cachedBitmap) {
      return cachedBitmap;
    }
  
    const url = `${config.apiEndpoint}/devices/get_bitmap`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  
    if (!response.ok) {
      throw new Error(`Failed to obtain bitmap: ${response.statusText}`);
    }
  
    const base64String = await response.text();
    const sanitizedBase64String = base64String.replace(/"/g, '');
    const imageUrl = `data:image/bmp;base64,${sanitizedBase64String}`;    
    localStorage.setItem(`bitmap-${deviceName}`, imageUrl);
  
    return imageUrl;
  };
  