import './date.scss'
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'components/functions/utils/dates';

const DateTime = ({ date, isLoading, isFlashing, className }) => {
  const { t } = useTranslation('global');
  const { formattedDate, formattedTime } = formatDateTime(date);
  return (
    <div className={`flex items-center gap-8 farm-date-wrapper${className ? ` ${className}` : ''}`}>
      <span className="text-2xl farm-date-emoji">
        📷
      </span>
      { isLoading || isFlashing ? (
        <time className={`farm-date`}>
          {t('loading')}
        </time>
      ) : (
        <>
        <time className={`farm-date`}>
          {formattedDate}
        </time>
        <time className={`farm-date-time`}>
          {formattedTime}
        </time>
        </>
      )}
    </div>
  );
  
};

export default React.memo(DateTime);
