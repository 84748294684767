import './selectors.scss';
import React, { useState, useEffect, useRef } from 'react';
import {ReactComponent as Chevron} from 'assets/global/chevron-down-stroke-small.svg';
import useWindowWidth from 'components/functions/utils/useWindowWidth';
import { Modal } from './modals';
import Button from './buttons';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckboxUnchecked } from 'assets/gallery/checkbox.svg';
import { ReactComponent as CheckboxChecked } from 'assets/gallery/checkbox-selected.svg';

const Checkbox = ({ checked, onChange, label }) => {
  return (
    <div className="checkbox-wrapper" onClick={onChange}>
      {checked ? <CheckboxChecked /> : <CheckboxUnchecked />}
      {label && <span className="checkbox-label">{label}</span>}
    </div>
  );
};

const Dropdown = ({ id, staticIcon, items = [], onChange, small = false, mobileFill, className = '', defaultIndex = 0 }) => {
  const initialIndex = defaultIndex;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items?.[initialIndex] || items?.[0]);
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const dropdownRef = useRef(null);
  const { isMobile } = useWindowWidth();
  const { t, i18n } = useTranslation('global');

  // Outside click closes dropdown
  const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
      }
  };

  // Open modal
  const handleOpenModal = () => {
      setIsModalOpen(true);
  };

  // Close modal
  const handleCloseModal = () => {
      setIsModalOpen(false);
  };

  useEffect(() => {
      if (isDropdownOpen) {
          document.addEventListener('mousedown', handleOutsideClick);
      } else {
          document.removeEventListener('mousedown', handleOutsideClick);
      }

      return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
      };
  }, [isDropdownOpen, items]);

  useEffect(() => {
      if (onChange && selectedItem !== null) {
          onChange(selectedItem, selectedIndex);
      }
  }, [onChange, selectedItem, selectedIndex]);

  const toggleOpen = () => setIsDropdownOpen(!isDropdownOpen);

  const selectItem = (item, index) => {
      setSelectedItem(item);
      setSelectedIndex(index);
      setIsDropdownOpen(false);
      setIsModalOpen(false);
  };

  // Switch from dropdown menu to modal on mobile and vice versa
  useEffect(() => {
      if (isDropdownOpen) {
          if (isMobile) {
              setIsDropdownOpen(false);
              setIsModalOpen(true);
          } else {
              setIsDropdownOpen(true);
              setIsModalOpen(false);
          }
      }
      if (isModalOpen) {
          if (isMobile) {
              setIsDropdownOpen(false);
              setIsModalOpen(true);
          } else {
              setIsDropdownOpen(true);
              setIsModalOpen(false);
          }
      }
  }, [isMobile]);

  // Update items when language changes
  useEffect(() => {
      const updateItems = () => {
          const translatedItems = items.map(item => ({
              ...item,
              label: t(item.label)
          }));
          setSelectedItem(translatedItems[selectedIndex]);
      };

      i18n.on('languageChanged', updateItems);
      updateItems(); // Initial run

      return () => {
          i18n.off('languageChanged', updateItems);
      };
  }, [items, t, selectedIndex, i18n]);

  return (
      <>
      <div className={`dropdown${mobileFill ? " mobile-fill" : ""} ${className}`} id={id} ref={dropdownRef}>
          <div
              className={`dropdown-item dropdown-item-selected ${small ? 'small-selected' : ''}`}
              onClick={!isMobile ? toggleOpen : handleOpenModal}
          >
              {selectedItem && (
                  <>
                      <div className="dropdown-svg">{staticIcon ? staticIcon : selectedItem.svg}</div>
                      {!small && <div className="dropdown-label">{selectedItem.label}</div>}
                      <Chevron className="dropdown-chevron-icon" />
                  </>
              )}
          </div>
          {isDropdownOpen && (
              <ul className="dropdown-menu">
                  {items.map((item, index) => (
                      <li key={index} onClick={() => selectItem(item, index)} className="dropdown-item">
                          {!staticIcon && <div className="dropdown-svg">{item.svg}</div>}
                          <div className="dropdown-label">{item.label}</div>
                      </li>
                  ))}
              </ul>
          )}
      </div>
      <Modal 
          isOpen={isModalOpen}
          heading={t("dropdown_menu.heading")}
          className="dropdown-menu-mobile"
          onClose={{action: handleCloseModal}}
      >
          <div className="action-buttons">
              {items.map((item, index) => (
                  <Button
                      key={index}
                      type="secondary"
                      color="purple"
                      iconFirst={item.svg}
                      label={item.label}
                      onClick={() => selectItem(item, index)}
                  />
              ))}
          </div>
      </Modal>
      </>
  );
};

const NumberInput = ({ unit, value, onChange, min, max }) => {
  const [inputValue, setInputValue] = useState(value);
  const [previousValue, setPreviousValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      commitValue();
    } else if (event.key === 'Escape') {
      cancelValue();
    }
  };

  const handleInputBlur = () => {
    commitValue();
  };

  const commitValue = () => {
    let newValue = Number(inputValue);

    if (min !== undefined && newValue < min) {
      newValue = min;
    } else if (max !== undefined && newValue > max) {
      newValue = max;
    }

    if (newValue !== value) {
      onChange(newValue);
    }
  };

  const cancelValue = () => {
    setInputValue(previousValue);
  };

  const handleFocus = () => {
    setPreviousValue(inputValue);
  };

  const handleClick = (event) => {
    event.target.select();
  };

  return (
    <div className="number-input">
      <input 
        type="number"
        value={inputValue}
        onChange={handleInputChange}
        onKeyUp={handleKeyUp}
        onBlur={handleInputBlur}
        onFocus={handleFocus}
        onClick={handleClick}
        className="number-input-field"
        min={min} 
        max={max}
      />
      <div className="number-input-unit">{unit}</div>
    </div>
  );
};


const StaticInput = ({name}) => {

  return (
    <div className="static-input">
      <div className="static-input-field">{name}</div>
    </div>
  );
};


const CombinedSelector = ({ children }) => {
  return (
    <div className="combined-selector">
      {children.map((Child, index) => {
        const hasMobileFill = React.isValidElement(Child) && Child.props.mobileFill;
        const itemClass = hasMobileFill ? 'selector-item mobile-fill' : 'selector-item';

        return (
          <div key={index} className={itemClass}>
            {Child}
          </div>
        );
      })}
    </div>
  );
};
  

export {Dropdown, Checkbox, NumberInput, StaticInput, CombinedSelector};
