import React, { useEffect, useState } from 'react';
import './modals.scss';
import { BoxedIcon } from './boxedicon';
import ReactDOM from 'react-dom';
import Button from './buttons.js';
import { ReactComponent as Cross } from 'assets/global/cross-stroke-medium.svg';
import { useTranslation } from 'react-i18next';

const Modal = ({ 
  name,
  large,
  isOpen,
  icon,
  heading,
  subheading,
  children,
  actions,
  onClose,
  className
}) => {
  const [animate, setAnimate] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const largeClass = large ? `modal-large`: '';
  const { t, i18n } = useTranslation('global');

  useEffect(() => {
    if (isOpen) {
      setIsClosing(false);
      // add a slight delay before starting the animation
      setTimeout(() => setAnimate(true), 20);
    } else {
      setIsClosing(true);
      setTimeout(() => {
        setAnimate(false);
        setIsClosing(false);
      }, 300);
    }
  }, [isOpen]);

  if (!isOpen && !animate) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={`modal-container${className ? (' ' + className) : ''}${animate ? ' animate' : ''}${isClosing ? ' closing' : ''}`}>
      <div className={`modal-blanket`} onClick={onClose.action}></div>
      <div className={`modal ${largeClass}`} onClick={e => e.stopPropagation()}>
        <Button type="secondary" color="purple" iconFirst={<Cross/>} onClick={onClose.action} className="modal-close"/>
        <div className='modal-content'>
          {icon && <BoxedIcon state="idle" icon={icon} />}
          {heading && 
            <div className='flex flex-col'>
              <h3 className="modal-heading">{heading}</h3>
              {subheading && <p className='modal-subheading'>{subheading}</p>}
            </div>
          }
          {children}
          {actions && 
            <div className='action-buttons'>
              <Button
                type="secondary"
                color="purple"
                label={onClose.label || t('cancel')}
                className={onClose.className || ''}
                onClick={onClose.action || onClose}
              />
              {actions.map((button, index) => (
                <Button 
                  key={index}
                  type="primary"
                  color={button.color || "green"}
                  label={button.label}
                  className={button.className}
                  onClick={button.action}
                />
              ))}
            </div>
          }
        </div>
      </div>
    </div>,
    document.body
  );
};

export { Modal };
