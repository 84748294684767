// src/slices/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { deviceApi } from 'api/deviceApi';

const initialState = {
  accessToken: localStorage.getItem('access_token'),
  refreshToken: localStorage.getItem('refresh_token'),
  deviceName: localStorage.getItem('deviceName'),
  deviceId: localStorage.getItem('deviceId'),
  loading: !localStorage.getItem('access_token'),
  tokenExpired: false,
  isAuthenticated: !!localStorage.getItem('access_token'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setTokenExpired(state, action) {
      state.tokenExpired = action.payload;
    },
    setCredentials(state, action) {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.isAuthenticated = !!accessToken;
      state.loading = false;
      state.tokenExpired = false;
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
    },
    logOut(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.deviceName = null;
      state.deviceId = null;
      state.isAuthenticated = false;
      state.loading = false;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('deviceName');
      localStorage.removeItem('deviceId');
    },
    setDeviceInfo(state, action) {
      const { name, id } = action.payload;
      state.deviceName = name;
      state.deviceId = id;
      localStorage.setItem('deviceName', name);
      localStorage.setItem('deviceId', id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        deviceApi.endpoints.login.matchPending,
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        deviceApi.endpoints.login.matchFulfilled,
        (state, action) => {
          state.isAuthenticated = true;
          state.accessToken = action.payload.access_token;
          state.refreshToken = action.payload.refresh_token;
          state.loading = false;
          localStorage.setItem('access_token', action.payload.access_token);
          localStorage.setItem('refresh_token', action.payload.refresh_token);
        }
      )
      .addMatcher(
        deviceApi.endpoints.login.matchRejected,
        (state, action) => {
          state.isAuthenticated = false;
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addMatcher(
        deviceApi.endpoints.logout.matchPending,
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        deviceApi.endpoints.logout.matchFulfilled,
        (state) => {
          state.isAuthenticated = false;
          state.accessToken = null;
          state.refreshToken = null;
          state.deviceName = null;
          state.deviceId = null;
          state.loading = false;
        }
      )
      .addMatcher(
        deviceApi.endpoints.logout.matchRejected,
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addMatcher(
        deviceApi.endpoints.refreshToken.matchPending,
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        deviceApi.endpoints.refreshToken.matchFulfilled,
        (state, action) => {
          state.accessToken = action.payload.access_token;
          state.refreshToken = action.payload.refresh_token;
          state.loading = false;
        }
      )
      .addMatcher(
        deviceApi.endpoints.refreshToken.matchRejected,
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addMatcher(
        deviceApi.endpoints.getDeviceInfo.matchFulfilled,
        (state, action) => {
          state.deviceName = action.payload.name;
          state.deviceId = action.payload.id;
        }
      )
      .addMatcher(
        deviceApi.endpoints.getDeviceInfo.matchRejected,
        (state, action) => {
          state.error = action.payload;
        }
      );
  },
});

export const { setLoading, setTokenExpired, setCredentials, logOut, setDeviceInfo } = authSlice.actions;

export default authSlice.reducer;
