function nmToRgb(wavelength) {
  let rgbFactor = [0.0, 0.0, 0.0];
  let rgb = [0, 0, 0];

  if (wavelength >= 400 && wavelength < 440) {
      rgbFactor[0] = -(wavelength - 440) / (440 - 400);
      rgbFactor[1] = 0.0;
      rgbFactor[2] = 1.0;
  } else if (wavelength >= 440 && wavelength < 490) {
      rgbFactor[0] = 0.0;
      rgbFactor[1] = (wavelength - 440) / (490 - 440);
      rgbFactor[2] = 1.0;
  } else if (wavelength >= 490 && wavelength < 510) {
      rgbFactor[0] = 0.0;
      rgbFactor[1] = 1.0;
      rgbFactor[2] = -(wavelength - 510) / (510 - 490);
  } else if (wavelength >= 510 && wavelength < 580) {
      rgbFactor[0] = (wavelength - 510) / (580 - 510);
      rgbFactor[1] = 1.0;
      rgbFactor[2] = 0.0;
  } else if (wavelength >= 580 && wavelength < 645) {
      rgbFactor[0] = 1.0;
      rgbFactor[1] = -(wavelength - 645) / (645 - 580);
      rgbFactor[2] = 0.0;
  } else if (wavelength >= 645 && wavelength <= 700) {
      rgbFactor[0] = 1.0;
      rgbFactor[1] = 0.0;
      rgbFactor[2] = 0.0;
  } else {
      rgbFactor[0] = 0.0;
      rgbFactor[1] = 0.0;
      rgbFactor[2] = 0.0;
  }

  rgb[0] = Math.floor(rgbFactor[0] * 255);
  rgb[1] = Math.floor(rgbFactor[1] * 255);
  rgb[2] = Math.floor(rgbFactor[2] * 255);

  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
}

function generateGradient() {

    let gradientColors = [];
    for (let i = 400; i <= 700; i+=10) {
        gradientColors.push(nmToRgb(i));
    }

    let gradient = 'linear-gradient(90deg, ' + gradientColors.join(',') + ')';
    return gradient;
}

  
  export {nmToRgb, generateGradient};