import './programming.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { initializeBlocklyWorkspace, sendProgramCommand, saveProgram, setupSocketConnection, generateProgramSvg } from 'components/functions/blockly/blocklyManager';
import ActionBar from 'components/pages/programming/actionBar';
import {
  SaveProgramModal,
  OpenProgramModal,
  ExamplesModal,
  LockUnlockModal
} from 'components/pages/programming/modals'; 
import useWindowWidth from './functions/utils/useWindowWidth';
import InfoToast from './global/toasts';
import ProgramRunning from 'components/pages/programming/running';
import { ProgramCode } from 'components/pages/programming/histProgram';
import { getProgramsJSON, sendStopCommandDevice, getLockStatus } from './functions/blockly/blocklyEndpointFunctions'; 
import { jsonToBlocklyWorkspace } from './functions/blockly/utils'; 

function Programming() {
  useEffect(() => {
    // Scroll to the top of the page
    document.getElementById("content").scrollTo(0, 0);
  }, []);

  const { t, i18n } = useTranslation(['programming', 'global']);
  const { isMobile } = useWindowWidth();
  const { accessToken, deviceId } = useSelector(state => state.auth);
  const blocklyDiv = useRef(null);
  const [workspace, setWorkspace] = useState(null); 
  const [programRunningJSON, setProgramRunningJSON] = useState(null);
  const [svgData, setSvgData] = useState(null);
  const [isProgramRunning, setIsProgramRunning] = useState(false);
  const [latestBlocklyStatus, setLatestBlocklyStatus] = useState(null);
  const [allHistPrograms, setAllHistPrograms] = useState(null);
  
  const [lockedToast, setLockedToast] = useState(false);
  const [wrongPinToast, setWrongPinToast] = useState(false);
  const [isLocked, setIsLocked] = useState(false); 
  const [lockedUntil, setLockedUntil] = useState(null); 
  const [isCountdownVisible, setIsCountdownVisible] = useState(false); 

  // Modals
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const handleSaveClick = () => {
    setIsSaveModalOpen(true); 
  };
  const handleCloseSaveModal = () => {
    setIsSaveModalOpen(false);
  };

  const [isOpenModalOpen, setIsOpenModalOpen] = useState(false);
  const handleOpenClick = () => {
    setIsOpenModalOpen(true); 
  };
  const handleCloseOpenModal = () => {
    setIsOpenModalOpen(false);
  };

  const [isExamplesModalOpen, setIsExamplesModalOpen] = useState(false);
  
  const [isLockModalOpen, setIsLockModalOpen] = useState(false); 
  const handleLockClick = () => {
    setIsLockModalOpen(true); 
  };
  
  const handleCloseLockModal = () => {
    setIsLockModalOpen(false);
  };

  const handleWrongPinToast = () => {
    setWrongPinToast(true);
  };

  const initializeLockStatus = async () => {
      
    try {
      const lockStatus = await getLockStatus(accessToken);
      setIsLocked(lockStatus.isLocked);
      setLockedUntil(lockStatus.lockedUntil);
    } catch (error) {
      console.error('Error fetching initial lock status:', error);
    }
  };


  const handleExamplesClick = () => {
    setIsExamplesModalOpen(true); 
  };
  const handleCloseExamplesModal = () => {
    setIsExamplesModalOpen(false);
  };

  const handleDuplicateProgram = (selectedProgramJson) => {
    jsonToBlocklyWorkspace(selectedProgramJson, workspace); 
    handleCloseOpenModal();
    handleCloseExamplesModal();
  };
  
  const [currentlySelectedProgramId, setCurrentlySelectedProgramId] = useState(null);
  const [currentlySelectedProgramJson, setCurrentlySelectedProgramJson] = useState(null);

  const handleSelectProgram = (selectedProgramId, selectedProgramJSON) => {
    
    if (currentlySelectedProgramId === selectedProgramId) {
      setCurrentlySelectedProgramId(null); 
      setCurrentlySelectedProgramJson(null); 
    } else {
      setCurrentlySelectedProgramId(selectedProgramId); 
      setCurrentlySelectedProgramJson(selectedProgramJSON); 
    }
  };

  const showLockErrorToast = () => {
    setLockedToast(true);
    console.log("lock toast context changed");
  };

  const saveProgramHandler = async (programName) => {
    await saveProgram(
      workspace,
      programName,
      accessToken,
      setAllHistPrograms,
      setIsSaveModalOpen
    );
  };

  const fetchPrograms = async () => {
    try {
      const newProgramRunningJSON = await getProgramsJSON(accessToken, 0, true); 
      setProgramRunningJSON(newProgramRunningJSON);
      const allPrograms = await getProgramsJSON(accessToken, 18, false); 
      setAllHistPrograms(allPrograms);
    } catch (error) {
      console.error('There was a problem fetching the programs:', error);
    }
  };

  useEffect(() => {
    generateProgramSvg(programRunningJSON, setSvgData, setIsProgramRunning);
  }, [programRunningJSON]);

  useEffect(() => {

    initializeBlocklyWorkspace(blocklyDiv, t, i18n, workspace, setWorkspace);
    fetchPrograms();  

  },  [t, i18n.language, accessToken]);

  useEffect(() => {
    
    initializeLockStatus();

  },[accessToken]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const lockStatus = await getLockStatus(accessToken);
        setIsLocked(lockStatus.isLocked);
        setLockedUntil(lockStatus.lockedUntil);
      } catch (error) {
        console.error('Error fetching lock status during interval:', error);
      }
    }, 10000); 
  
    return () => clearInterval(intervalId);
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      setupSocketConnection(accessToken, deviceId, setLatestBlocklyStatus, setProgramRunningJSON); 
    }
  }, [accessToken]);

  useEffect(() => {
    if (latestBlocklyStatus) {
      setIsProgramRunning(false);
      setTimeout(() => setLatestBlocklyStatus(null), 10000);
    }
  }, [latestBlocklyStatus]);

  const sendCommand = async () => {
    await sendProgramCommand(
      workspace,
      accessToken,
      t,
      setProgramRunningJSON,
      setAllHistPrograms,
      setIsProgramRunning,
      setIsLocked,
      setIsCountdownVisible,
      showLockErrorToast
    );
  };


  function stopRunningProgram() {
    sendStopCommandDevice(accessToken);
    setIsProgramRunning(false);
  }    
  return (
    <>
    {!isMobile ? (
      <>
      <ActionBar
        onOpenClick={handleOpenClick}
        onExamplesClick={handleExamplesClick}
        onSaveClick={handleSaveClick}
        onUndo={() => workspace.undo(false)}
        onRedo={() => workspace.undo(true)}
        onClear={() => workspace.clear()}
        isLocked={isLocked}
        onLockClick={handleLockClick}
        onRunCommand={sendCommand}
        isCountdownVisible={isCountdownVisible}
      />
      <div className="main">
        <div ref={blocklyDiv} id="blockly-div"/>
      </div>
      </>
    ) : (
      // Mobile version
      <>
      <h4 className='m0'>{t('mobile.heading')}</h4>
      <div className='main'>
        <div className="programs">
          {allHistPrograms ? (
            allHistPrograms.map((program, index) => (
              <ProgramCode
                key={index}
                programId={index}
                program={program}
                currentlySelectedProgramId={currentlySelectedProgramId}
                handleSelectProgram={handleSelectProgram}
                onRunCommand={sendCommand} 
              />
            ))
          ) : (
            <div className="loading text-placeholder">{t('empty')}</div>
          )}
        </div>
      </div>
      <div className='text-purple'>{t('mobile.subheading')}</div>
      <div className="hide" ref={blocklyDiv}/>
      </>
    )}

    {/* Toasts */}
    {/* Program running */}
    {isProgramRunning && (
      <ProgramRunning 
      isProgramRunning={isProgramRunning}
      programSvgComponent={svgData} 
      programRunningJSON={programRunningJSON}
      currentWorkspace={workspace}
      onStopProgram={stopRunningProgram} 
      />
    )}
    {latestBlocklyStatus && <InfoToast
      message={latestBlocklyStatus["status"]} 
      color={latestBlocklyStatus["code"] > 0 ? "green" : "red"} 
      actions={[{ action: () => setLatestBlocklyStatus(null) }]}
    />}
    {/* Program is currently locked */}
    {lockedToast && 
    <InfoToast
      message={t('toasts.locked')}
      color="orange"
      actions={[{ action: () => setLockedToast(false) }]}
    />}
    {wrongPinToast && 
    <InfoToast
      message={t('toasts.wrongpin')}
      color="orange"
      className="wrong-pin"
      actions={[{ action: () => setWrongPinToast(false) }]}
    />}
    {/* Modals */}
        <SaveProgramModal
          isOpen={isSaveModalOpen}
          onClose={handleCloseSaveModal}
          onSave={saveProgramHandler}
        />

        <OpenProgramModal
          isOpen={isOpenModalOpen}
          onClose={handleCloseOpenModal}
          programs={allHistPrograms}
          onSelect={handleSelectProgram}
          onDuplicate={handleDuplicateProgram}
        />

        <ExamplesModal
          isOpen={isExamplesModalOpen}
          onClose={handleCloseExamplesModal}
          onSelect={handleSelectProgram}
          onDuplicate={handleDuplicateProgram}
        />

        <LockUnlockModal
          accessToken={accessToken}
          isOpen={isLockModalOpen}
          isLocked={isLocked}
          onClose={handleCloseLockModal}
          setWrongPinToast={handleWrongPinToast} 
          lockedUntil={lockedUntil}
        />

    </>
  );
}

export default Programming;
