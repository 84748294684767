
import config from 'config';
import JSZip from 'jszip';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { saveAs } from 'file-saver';

const ffmpeg = new FFmpeg();

const readFileAsArrayBuffer = async (dataUrl) => {
  const response = await fetch(dataUrl);
  return response.arrayBuffer();
};

export async function downloadTimelapse(images) {
  if (images.length === 0) {
    console.error('No images provided for timelapse.');
    return;
  }

  // Load FFmpeg.wasm
  if (!ffmpeg.loaded) {
    await ffmpeg.load();
  }

  // Write images to FFmpeg.wasm file system
  for (let i = 0; i < images.length; i++) {
    const dataUrl = `data:image/jpeg;base64,${images[i].img}`;
    const imgBuffer = await readFileAsArrayBuffer(dataUrl);
    await ffmpeg.writeFile(`img${i}.jpg`, new Uint8Array(imgBuffer));
  }

  // Generate the input file list with durations
  let inputFileList = images.map((_, i) => `file img${i}.jpg\nduration 0.3`).join('\n');
  inputFileList += `\nfile img${images.length - 1}.jpg\nduration 0.3\n`; // Ensure the last frame is shown for the correct duration
  await ffmpeg.writeFile('input.txt', inputFileList);

  // Run FFmpeg to create an MP4 video with delay
  await ffmpeg.exec(['-f', 'concat', '-safe', '0', '-i', 'input.txt', '-vsync', 'vfr', '-pix_fmt', 'yuv420p', 'output.mp4']);

  // Read the result
  const data = await ffmpeg.readFile('output.mp4');
  const blob = new Blob([data.buffer], { type: 'video/mp4' });

  // Create a URL for the video file
  const videoURL = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = videoURL;
  a.download = 'timelapse.mp4';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(videoURL);
}

export const getHistoricalImagesDevice = (accessToken, amount, page) => {
    const url = `${config.apiEndpoint}/devices/picture?amount=${amount}&page=${page}`;
  
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to get pictures: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => data) 
      .catch(error => {
        console.error('Error:', error);
        return []; 
      });
  };

export const deletePicturesDevice = (accessToken, images) => {
    const url = `${config.apiEndpoint}/devices/picture`;
    console.log(images);
    const formattedDatetimes = images.map(images => {
      const date = new Date(images.date);
      return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    });
    const datetimeJsonString = JSON.stringify(formattedDatetimes);
  
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ datetimes: datetimeJsonString }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      // console.log('Success:', data);
      return data;
    })
    .catch(error => {
      // console.error('Error:', error);
      return [];
    });
  };
  

  export const downloadImage = (image) => {
    // Assuming image is an object with 'img' and 'date' properties
    const zip = new JSZip();
    const folder = zip.folder("images");

    // Extract the base64 data from the image object
    const imgData = image.img.replace(/^data:image\/(png|jpeg);base64,/, "");
    const imgBlob = b64toBlob(imgData, "image/jpeg");

    // Create a safe file name from the image date
    const safeFileName = image.date.replace(/:/g, '-') + ".jpg";
    folder.file(safeFileName, imgBlob, { base64: true });

    // Generate the zip file and trigger the download
    zip.generateAsync({ type: "blob" })
        .then((content) => {
            saveAs(content, "tinyfarms-image.zip");
        });
};
  
export const downloadSelectedImages = (selectedImageObjects) => {
  const zip = new JSZip();
  const folder = zip.folder("images");
  
  selectedImageObjects.forEach((image) => {
    const imgData = image.img.replace(/^data:image\/(png|jpeg);base64,/, "");
    const imgBlob = b64toBlob(imgData, "image/jpeg");
    const safeFileName = image.date.replace(/:/g, '-') + ".jpg";
    folder.file(safeFileName, imgBlob, { base64: true });
  });

  zip.generateAsync({ type: "blob" })
    .then((content) => {
      saveAs(content, "tinyfarms-images.zip");
    });
};

  
  // Convert a base64 string to a Blob
  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
