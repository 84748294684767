import './toasts.scss';
import ReactDOM from 'react-dom';
import Button from './buttons';
import { useTranslation } from 'react-i18next';

const InfoToast = ({
  color,
  emoji,
  message,
  actions,
  preview,
  className,
  children
}) => {
  const { t } = useTranslation('global');

  return ReactDOM.createPortal(
    <>
      <div className={`toast toast-${color || "purple"} ${className || ""}`}>
        {preview && <div className='toast-preview' onClick={preview.action}>{preview.element}</div>}
        <div className="toast-message">
          {emoji && <span className='emoji-md'>{emoji}</span>}
          <div className="toast-label small bold">{message}</div>
        </div>
        {actions && <div className="button-group">
          {actions.map((button,
          index) => (
            <Button 
              key={index}
              id={button.id || ""}
              type="primary"
              color={button.color || color || "purple"}
              small
              label={button.label || t('close')}
              onClick={button.action}
            />
          ))}
          </div>
        }
      </div>
      {children}
    </>,
    document.getElementById('content-wrapper')
  );
};


export default InfoToast;
