import './infoCircle.scss';
import React, { useState } from 'react';
import {ReactComponent as InfoCircle} from 'assets/controls/info-circle.svg';
import {Modal} from './modals.js';
import Chip from './chips';
import { useTranslation } from 'react-i18next';


const InfoCircleSensor = ({ name, keys_name, icon }) => {
  const { t } = useTranslation('controls');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInfoCircleClick = () => {
    setIsModalOpen(true); 
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Use keys_name as the translation key prefix
  const headingKey = `${keys_name}_heading`;
  const subheadingTopKey = `${keys_name}_subheading_top`;
  const subheadingBottomKey = `${keys_name}_subheading_bottom`;
  const iconMin = `${keys_name}_iconMin`;
  const iconMax = `${keys_name}_iconMax`;
  const valueMin = `${keys_name}_value_min`;
  const valueMax = `${keys_name}_value_max`;
  const valueIdeal = `${keys_name}_value_ideal`;


  return (
    <>
      <InfoCircle className="info-icon" onClick={handleInfoCircleClick} />
      <Modal
        name={name}
        isOpen={isModalOpen}
        icon={icon}
        heading={t(headingKey)}
        subheading={
          <>
          <p><b>{t(subheadingTopKey)}</b></p>
          <p>{t(subheadingBottomKey)}</p>
          </>
        }
        onClose={{action: handleCloseModal}}
      >
        <div className='chip-group'>
          <Chip secondary color='purple' iconFirst={t(iconMin)} label={t('min') + t(valueMin)}/>
          <Chip secondary color='purple' iconFirst={t(iconMax)} label={t('max') + t(valueMax)}/>
          <Chip secondary color='green' iconFirst='🌱' label={t('ideal') + t(valueIdeal)}/>
        </div>
      </Modal>
    </>
  );
};


const InfoCircleActuator = ({ name, keys_name, icon }) => {
  const { t } = useTranslation('controls');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInfoCircleClick = () => {
    setIsModalOpen(true); 
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Use keys_name as the translation key prefix
  const headingKey = `${keys_name}_heading`;
  const subheadingKey = `${keys_name}_subheading`;
  const paragraphsList = `${keys_name}_paragraphs`;
  const modalList = `${keys_name}_howto_list`;
  const modalNote = `${keys_name}_howto_note`;
  const paragraphs = t(paragraphsList).split('\n');
  const bulletPoints = t(modalList).split('\n');

  return (
    <>
      <InfoCircle className="info-icon" onClick={handleInfoCircleClick} />
      <Modal
        name={name}
        isOpen={isModalOpen}
        icon={icon}
        heading={t(headingKey)}
        subheading={t(subheadingKey)}
        onClose={{action: handleCloseModal}}
      >
        {paragraphsList && t(paragraphsList).trim() !== '' && t(paragraphsList) !== paragraphsList &&
          <div className='flex flex-col'>
            {paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        }
        <div className="modal-howto">
          <h4>{t('controls_howto_heading')}</h4>
          <ol>
          {bulletPoints.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
          </ol>
          {
          modalNote && t(modalNote).trim() !== '' && t(modalNote) !== modalNote && 
          <p className='modal-howto-note'><b>{t('controls_howto_note')}</b> {t(modalNote)}</p>
          }
        </div>
      </Modal>
    </>
  );
};



export {InfoCircleSensor, InfoCircleActuator};
