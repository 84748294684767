import { useState, useEffect } from 'react';

function getWindowWidth() {
  return window.innerWidth;
}

export default function useWindowWidth() {
  const vwMobile = 800;
  const vwTablet = 1024;
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= vwMobile;
  const isTablet = windowWidth > vwMobile && windowWidth <= vwTablet;

  // Return an object with isMobile and isTablet properties
  return { windowWidth, isMobile, isTablet };
}
