import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pumpValues: {
        'water-pump': "11",
        'nutrients-pump': "18",
        'air-pump': "40"
    },
    selectedOptions: {
        'water-pump': 0,
        'nutrients-pump': 0,
        'air-pump': 0
    },
};

const pumpsSlice = createSlice({
    name: 'pumps',
    initialState,
    reducers: {
        setPumpValue(state, action) {
            const { pumpId, value } = action.payload;
            state.pumpValues[pumpId] = value;
        },
        setSelectedOption(state, action) {
            const { pumpId, selectedIndex } = action.payload;
            if (!state.selectedOptions) {
                state.selectedOptions = {};
            }
            state.selectedOptions[pumpId] = selectedIndex;
        },
    },
});

export const { setPumpValue, setSelectedOption } = pumpsSlice.actions;

export default pumpsSlice.reducer;
