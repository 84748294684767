import React, {useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { formatLocalizedDate } from 'components/functions/utils/dates';
import LoadingSpinner from 'components/global/loadingSpinner';

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin,
  {
    id: 'customVerticalLine',
    beforeDatasetsDraw: (chart) => {
      const activeElements = chart.getActiveElements();
      if (activeElements.length === 0) return;

      const activeElement = activeElements[0];
      if (!activeElement.element) return;

      const ctx = chart.ctx;
      const x = activeElement.element.x;
      const topY = chart.chartArea.top;
      const bottomY = chart.chartArea.bottom;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#9A93FB';
      ctx.stroke();
      ctx.restore();
    },
  }
);

const formatChartDate = (dateString, language) => {
  const formattedDate = formatLocalizedDate(dateString, 'MMM d', language);
  const formattedTime = formatLocalizedDate(dateString, 'HH:mm', language);
  return `${formattedDate}\n${formattedTime}`;
};

const getLineChartConfig = (dataType, data, timeInterval, language) => {
  // Check if data is undefined or empty
  if (!data || data.length === 0) {
    return { lineChartConfig: null, sortedData: [] };
  }

  // Sort the data by date in ascending order
  const sortedData = data.sort((a, b) => new Date(a.x) - new Date(b.x));

  const gradientColors = {
    co2: ["24, 216, 104", "175, 207, 196"],
    humidity: ["26, 180, 255", "240, 199, 117"],
    temperature: ["255, 112, 112", "244, 209, 37", "102, 204, 255"],
  };
  const maxValues = {
    co2: "5000",
    humidity: "100",
    temperature: "45",
  };
  const fillOpacity = '0.2';
  const color = gradientColors[dataType] || ["93, 85, 216", "154, 147, 251"];
  const max = maxValues[dataType] || ["0", "100"];

  const lineChartConfig = {
    labels: sortedData.map((entry) => formatChartDate(entry.x, language)),
    datasets: [
      {
        label: dataType,
        data: sortedData.map((entry) => entry.y),
        fill: "start",
        spanGaps: false,
        backgroundColor: (context) => {
          if(!context.chart.chartArea) {
            return;
          }
          const { ctx, data, chartArea: {top, bottom} } = context.chart;
          const gradient = ctx.createLinearGradient(0, top, 0, bottom);
          const colorSections = 1 / (color.length - 1);
          for(let i = 0; i < color.length; i++){
            gradient.addColorStop(0 + i * colorSections, 'rgba(' + color[i] + ', ' + fillOpacity + ')');
          }
          return gradient;
        },
        borderColor: (context) => {
          if(!context.chart.chartArea) {
            return;
          }
          const { ctx, data, chartArea: {top, bottom} } = context.chart;
          const gradient = ctx.createLinearGradient(0, top, 0, bottom);
          const colorSections = 1 / (color.length - 1);
          for(let i = 0; i < color.length; i++){
            gradient.addColorStop(0 + i * colorSections, 'rgba(' + color[i] + ', 1)');
          }
          return gradient;
        },
        cubicInterpolationMode: 'monotone',
        borderWidth: 3,
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 6,
        pointBorderWidth: 1,
        pointBorderColor: (context) => context.active ? '#ffffff' : 'transparent',
        pointBackgroundColor: (context) => context.active ? '#9A93FB' : 'transparent'
      },
    ],
  };

  return { lineChartConfig, sortedData };
};

const getLineChartOptions = (
  paddedMinValue,
  paddedMaxValue,
  onValueChange,
  dataType,
  chartData,
  lastSentDataPointRef,
  timeInterval,
  sortedData
) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        type: 'category',
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          maxRotation: 45,
          minRotation: 45,
          align: 'start',
          font: {
            family: 'Schibsted Grotesk',
            size: 12,
            style: 'normal',
            weight: '400',
            lineHeight: 1.2,
          },
        },
        grid: {
          display: true,
          color: '#F0F0FF',
        },
      },
      y: {
        type: 'linear',
        ticks: {
          callback: function(value, index, values) {
            if (index === 0 || index === values.length - 1) {
              return '';
            }
            return parseFloat(value).toFixed(2);
          },
          color: '#655DDA',
          autoSkip: true,
          maxTicksLimit: 6,
          align: 'right',
          font: {
            family: 'Schibsted Grotesk',
            size: 12,
            style: 'normal',
            weight: '400',
            lineHeight: 1.2,
          },
        },
        grid: {
          display: true,
          color: '#F0F0FF',
        },
        min: paddedMinValue,
        max: paddedMaxValue,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      customVerticalLine: {
        id: 'customVerticalLine',
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
    onHover: (event, activeElements) => {
      if (activeElements && activeElements.length > 0) {
        const activeElement = activeElements[0];
        const dataIndex = activeElement.index;
        const activePointData = sortedData[dataIndex];
        if (lastSentDataPointRef.current === null ||
            lastSentDataPointRef.current.data !== activePointData.y ||
            lastSentDataPointRef.current.date !== activePointData.x) {
          if (typeof onValueChange === 'function') {
            onValueChange(activePointData.x, activePointData.y, dataType);
          }
          lastSentDataPointRef.current = {
            date: activePointData.x,
            data: activePointData.y
          };
        }
      }
    },
    animation: false,
    elements: {
      line: {
        tension: 0.4,
        spanGaps: false
      }
    },
  };
};

const SingleChart = ({ dataType, data, onValueChange, id, timeInterval }) => {
  const { i18n } = useTranslation();
  const lastSentDataPointRef = useRef(null);
  const chartRef = useRef(null);

  const { lineChartConfig, sortedData } = getLineChartConfig(dataType, data, timeInterval, i18n.language);

  const paddingPercentage = 0.001;
  const minValue = sortedData.length > 0 ? Math.min(...sortedData.map((entry) => entry.y)) : 0;
  const maxValue = sortedData.length > 0 ? Math.max(...sortedData.map((entry) => entry.y)) : 100;
  const paddedMinValue = minValue * (1 - paddingPercentage);
  const paddedMaxValue = maxValue * (1 + paddingPercentage);

  const lineChartOptions = getLineChartOptions(
    paddedMinValue,
    paddedMaxValue,
    onValueChange,
    dataType,
    lineChartConfig,
    lastSentDataPointRef,
    timeInterval,
    sortedData
  );

  const sensorClass = `chart chart-${dataType}`;

  return (
    <div className='chart-container' id={id}>
      <div className={sensorClass}>
        {data && data.length > 0 && lineChartConfig ? (
          <Line
            ref={chartRef}
            data={lineChartConfig}
            options={lineChartOptions}
            width="auto"
          />
        ) : (
          <LoadingSpinner/>
        )}
      </div>
    </div>
  );
};

export default SingleChart;
