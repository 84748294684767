import './languagePicker.scss'
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/global/buttons';
import langs from 'langs';
import {ReactComponent as Chevron} from 'assets/global/chevron-down-stroke-small.svg';
import Tooltip from 'components/global/tooltips'
import useWindowWidth from 'components/functions/utils/useWindowWidth';
import { Modal } from 'components/global/modals';

const LanguagePicker = ({...buttonProps}) => {
  const { t, i18n } = useTranslation('global');
  const languages = i18n.options.resources.availableLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.slice(0, 2).toUpperCase());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { isMobile } = useWindowWidth();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.toLowerCase());
  }, [selectedLanguage]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const otherLanguages = languages.filter((lang) => lang.toUpperCase() !== selectedLanguage);

  const fullLanguageName = (code) => {
    const languageInfo = langs.where('1', code.toLowerCase());
    return languageInfo ? languageInfo.local : code.toUpperCase();
  }

  return (
    <>
    <div className="nav_text_wrapper language-selector" ref={wrapperRef}
      onClick={!isMobile ? () => setIsDropdownOpen(!isDropdownOpen) : () => setIsModalOpen(true)}
    >
      <Tooltip color="purple" body={selectedLanguage} position="right">
        <Button
          {...buttonProps}
          iconFirst="🌐"
          label={selectedLanguage}
          iconLast={<Chevron />}
          className={`navbar-button ${buttonProps.className || ''}`}
        />
      </Tooltip>
      {isDropdownOpen && (
        <div className="dropdown-menu">
          {otherLanguages.map((lang) => (
            <div key={lang} className="dropdown-item" onClick={() => setSelectedLanguage(lang.toUpperCase())}>
              {fullLanguageName(lang)} ({lang.toUpperCase()})
            </div>
          ))}
        </div>
      )}
    </div>
    {isModalOpen && (
      <Modal onClose={() => setIsModalOpen(false)}>
        <div className="modal-content">
          {otherLanguages.map((lang) => (
            <div key={lang} className="modal-item" onClick={() => { setSelectedLanguage(lang.toUpperCase()); setIsModalOpen(false); }}>
              {fullLanguageName(lang)} ({lang.toUpperCase()})
            </div>
          ))}
        </div>
      </Modal>
    )}
    </>
  );
};

export default LanguagePicker;
