import config from 'config';
import io from 'socket.io-client';

const sendInstruction = (instruction, _value, accessToken) => {
  const url = `${config.apiEndpoint}/devices/instruction`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      instruction: instruction,
      value: _value,
    }),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to send command: ${response.statusText}`);
      }
      return response;
    })
    .catch(error => {
      console.error('Error:', error);
      throw error; 
    });
};

const saveSensorValueToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify({value, expiry: Date.now() + 60 * 60 * 1000}));
};


var localSocket = undefined;
var localHandlers = {};
var lastValTime = {};

const setupSocket = (accessToken, deviceId, handlers = {}) => {
  
  const MAX_RETRIES = 10;
  let retries = 0;
  if (localSocket) {
    localSocket.disconnect();
  }
  localHandlers = {...localHandlers, ...handlers};

  localSocket = io(config.apiEndpoint, {
    extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
      },
  });

  const attemptReconnection = () => {
      if (retries < MAX_RETRIES) {
          // console.log(`Reconnection attempt #${retries + 1}`);
          retries += 1;
          localSocket.connect();
      } else {
          console.error('Max reconnection attempts reached.');
      }
  };
  localSocket.on("connect", () => {
      // console.log("Connected to WebSocket server!");
      localSocket.emit("subscribe_to_sensor_data");
  });

  localSocket.on('connect_error', (error) => {
      console.log('Connection Error: ', error);
      setTimeout(attemptReconnection, 1000); 
  });

  localSocket.on('error', (error) => {
      console.log('Error: ', error);
  });

  localSocket.on(`sensor_data_${deviceId}`, (data) => {
    const { sensor_type, value } = data;
  
    if (localHandlers[sensor_type] && ((Date.now() - (lastValTime[sensor_type] || 0)) > 1000)){
        lastValTime[sensor_type] = Date.now();
        localHandlers[sensor_type](value);
        saveSensorValueToSessionStorage(sensor_type, value);
    }
  });

  localSocket.on(`picture_data_${deviceId}`, (data) => {
    console.log('Picture data received');
    // Do something with the picture data
    // and when updating the tinyfarm picture viewer,
    // delete the handler data
    if (localHandlers['picture_data'] && localHandlers['picture_date']) {
      localHandlers['picture_data'](data);
      // put the iso formatted now datetime into the date handler
      const d = new Date();
      localHandlers['picture_date'](d.toISOString().slice(0,-5));
    }
  });

  localSocket.on(`picture_data_${deviceId}`, (data) => {
    console.log('Picture data received');
    // Do something with the picture data
    // and when updating the tinyfarm picture viewer,
    // delete the handler data
    if (localHandlers['picture_data'] && localHandlers['picture_date']) {
      localHandlers['picture_data'](data);
      // put the iso formatted now datetime into the date handler
      const d = new Date();
      localHandlers['picture_date'](d.toISOString().slice(0,-5));
    }
  });
  localSocket.on(`blockly_status_${deviceId}`, (data) => {
    // console.log('Blockly status received: ', data);
    if (localHandlers['blockly_status']) {
      localHandlers['blockly_status'](data);
    }
  }); 
  localSocket.on(`blockly_active_${deviceId}`, (data) => {
    // console.log('Blockly active received: ', data);
    if (localHandlers['blockly_json']) {
      localHandlers['blockly_json'](data);
      console.log('blockly_json handler called');
    }
  });
  return localSocket;
};

export function getInitialSensorValue(key) {
  const item = JSON.parse(sessionStorage.getItem(key));
  
  if (item && item.expiry > Date.now()) {
    return item.value;
  } else {
    return null;
  }
}

// camera endpoints
const getHistoricalImagesDevice = (accessToken, amount) => {
  const url = `${config.apiEndpoint}/devices/picture?amount=${amount}`;

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to get pictures: ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => data) 
    .catch(error => {
      console.error('Error:', error);
      return []; 
    });
};

const getLatestImageDate = (accessToken) => {
  const url = `${config.apiEndpoint}/devices/picture?latest=true`;

  fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to get pictures: ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => data) 
    .catch(error => {
      console.error('Error:', error);
      return []; 
    });
};

const takePictureDevice = (accessToken) => {
  return sendInstruction('picture', '1', accessToken);
};

export {sendInstruction, setupSocket, getLatestImageDate, getHistoricalImagesDevice, takePictureDevice}