import React, { useState, useEffect } from 'react';
import './toggle.css';

const ToggleBase = ({ id, disabled = false, defaultState = false, onChange }) => {
  const [isOn, setIsOn] = useState(defaultState);

  // Save state to session storage when it changes
  useEffect(() => {
    sessionStorage.setItem(id, isOn);
    if (onChange) {
      onChange(isOn);
    }
  }, [isOn, id, onChange]);

  // Handle click events
  const handleClick = () => {
    if (!disabled) {
      setIsOn(!isOn);
    }
  };

  const toggleClasses = `toggle ${isOn ? 'toggle-on' : ''} ${disabled ? 'toggle-disabled' : ''}`;

  return (
    <div className={toggleClasses} onClick={handleClick}>
      <div className="toggle-switch" />
    </div>
  );
};

const ToggleWithLabel = ({ id, disabled = false, onChange, isToggleOn }) => {
  const [isOn, setIsOn] = useState(isToggleOn);

  useEffect(() => {
    const defaultState = sessionStorage.getItem(id) === 'true';
    setIsOn(defaultState);
  }, [id]);

  const handleToggleChange = () => {
    if (!disabled) {
      setIsOn(!isOn);
    }
  };

  useEffect(() => {
    sessionStorage.setItem(id, isOn);
    if (onChange) {
      onChange(isOn);
    }
  }, [isOn, id, onChange]);

  const toggleClasses = `toggle-big ${isOn ? 'toggle-on' : ''} ${disabled ? 'toggle-disabled' : ''}`;

  return (
    <div className={toggleClasses} onClick={handleToggleChange}>
      <div className="toggle-switch">
        <div className="toggle-switch-handle">
          {isOn ? 'ON' : 'OFF'}
        </div>
      </div>
    </div>
  );
};

const Toggle = (props) => {
  return props.label ? <ToggleWithLabel {...props} /> : <ToggleBase {...props} />;
};

export default Toggle;
