import './learning.scss'
import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from 'components/pages/learning/breadCrumb'
import { HeaderElements } from 'components/pages/learning/headerElements' 
import { useTranslation } from 'react-i18next';
import QuizQuestion from 'components/pages/learning/quizQuestion';
import { BoxedIcon } from './global/boxedicon';
import LoadingSpinner from './global/loadingSpinner'

function LearningQuiz() {
  const { t, i18n } = useTranslation('learning');

  const [quiz, setSingleQuiz] = useState(null);

  const {number} = useParams();  
  const mainLocale = i18n.language.split('-')[0];
  

  useEffect(() => {
      const fetchSingleQuiz = async () => {
        try {
          const response = await fetch(
            `https://cdn.tinyfar.ms/api/quizzes?filters[unique_id][$eq]=${number}&locale=${mainLocale}&populate=deep`,
            
          );
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Server responded with ${response.status}: ${errorData.message || response.statusText}`);
          }
          const data = await response.json();
          setSingleQuiz(data.data[0]);
        } catch (err) {
          console.error(`Error fetching course: ${err.message}`);
        }
      };
  
      fetchSingleQuiz();
    }, []);
    

    return (
      <>
          { !quiz ? (
              <>
              <div className="action-bar">
                <BreadCrumb name={`${t('all_lessons')}`} />
              </div>
              <div className='main'>
                <LoadingSpinner/>
              </div>
              </>
          ) : (
              <>
              <div className="action-bar">
                {/* Add ": ${course.attributes.title}" when new structure is ready */}
                <BreadCrumb name={`${t('all_lessons')} › ${t('lesson')} ${quiz.attributes.unique_id} › ${t('quiz')}`} />
                <HeaderElements page="quiz" number={number} />
              </div>
                    
              <div className="main">
                <div className="lesson-header-wrapper gap-16 flex flex-column">
                  <BoxedIcon state="idle" icon="🤓" className="large"></BoxedIcon>
                    <h1 className="lesson-name">{quiz.attributes.title}</h1>
                </div>
                <div className="activity-description">{quiz.attributes.description}</div>
                <div className='quiz flex flex-col'>
                  {quiz.attributes.questions.map((item, index) => {
                      if (item.section_title) {
                          return (
                              <>
                                  <h3 key={`title-${index}`}>{item.section_title}</h3>
                                  <QuizQuestion key={`question-${index}`} questionData={item} />
                              </>
                          );
                      } else {
                          return <QuizQuestion key={index} questionData={item} />;
                      }
                  })}
                </div>
              </div>
              </>
          )}
      </>
  );
}
export default LearningQuiz; 