import React from 'react';
import { useEffect } from 'react';
import 'app.scss';
import { Route, Routes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetDeviceInfoQuery } from 'api/deviceApi';
import { setDeviceInfo, setLoading } from 'redux/slices/authSlice';

// Components
import useWindowWidth from 'components/functions/utils/useWindowWidth';
import PageTitle from 'components/global/pageTitle';
import ProtectedComponent from 'auth/protectedComponent'; 

// Pages
import Navbar from 'components/navbar';
import Controls from 'components/controls';
import Analytics from 'components/analytics';
import Gallery from 'components/gallery';
import Login from 'components/login';
import Learning from 'components/learning';
import LearningText from 'components/learningText';
import LearningActivity from 'components/learningActivity';
import LearningQuiz from 'components/learningQuiz';
import Programming from 'components/programming';
import Settings from 'components/settings';
import Logout from 'components/logout';

function App() {
  const { isMobile } = useWindowWidth();
  const { isAuthenticated, accessToken } = useSelector(state => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: deviceInfo, error, isLoading } = useGetDeviceInfoQuery(accessToken, {
    skip: !accessToken,
  });
  const currentPath = window.location.pathname.slice(1);
  const path = currentPath === "" ? "control-panel" : currentPath.replace(/\//g, '-').replace(/-\d+$/, '');
  
  useEffect(() => {
    if (deviceInfo) {
      dispatch(setDeviceInfo(deviceInfo));
    }
    if (error) {
      console.error('Failed to fetch device info:', error);
    }
    dispatch(setLoading(isLoading));
  }, [deviceInfo, error, isLoading, dispatch]);


  useEffect(() => {
    // Detecting touchscreen
    const isTouchScreen = window.matchMedia('(hover: none) and (pointer: coarse)').matches;

    if (isTouchScreen) {
      document.body.classList.add('touchscreen');
    } else {
      document.body.classList.remove('touchscreen');
    }

    // Clean up
    return () => {
      document.body.classList.remove('touchscreen');
    };
  }, []);

  return (

    <div id={path} className="page-wrapper">
      {isAuthenticated && location.pathname !== '/login' && location.pathname !== '/logout' && (
        <Navbar />
      )}
      <div id="content-wrapper">
        <div id="content">
        {isMobile && isAuthenticated && location.pathname !== '/login' && location.pathname !== '/logout' && (
            <PageTitle />
          )}
          <div className="page-content">
          <Routes>
            {/* Protected routes */}
            <Route path="/" element={<ProtectedComponent><Controls /></ProtectedComponent>} />
            <Route path="/programming" element={<ProtectedComponent><Programming /></ProtectedComponent>} />
            <Route path="/analytics" element={<ProtectedComponent><Analytics /></ProtectedComponent>} />
            <Route path="/gallery" element={<ProtectedComponent><Gallery /></ProtectedComponent>} />
            <Route path="/learning" element={<ProtectedComponent><Learning /></ProtectedComponent>} />
            <Route path="/learning/lesson/:number" element={<ProtectedComponent><LearningText /></ProtectedComponent>} />
            <Route path="/learning/activity/:number" element={<ProtectedComponent><LearningActivity /></ProtectedComponent>} />
            <Route path="/learning/quiz/:number" element={<ProtectedComponent><LearningQuiz /></ProtectedComponent>} />
            <Route path="/settings" element={<ProtectedComponent><Settings /></ProtectedComponent>} />
            {/* Non-protected routes */}
            <Route path="/logout" element={<Logout />} />
            <Route path="/login" element={<Login />} />
          </Routes>
          </div>
        </div>
      </div>
    </div>

  );
}

export default App;
