import './boxedicon.scss';
import React, { useEffect, useState } from 'react';
import { nmToRgb } from 'components/functions/utils/light';

const BoxedIcon = ({ state, icon, color = 'white', className='', onClick }) => {
  const stateClass = `icon-${state}`;
  const style = { backgroundColor: color };

  return (
    <div 
      className={`boxed-icon ${stateClass} ${className}`} 
      style={style}
      onClick={onClick} 
    >
      <div className="boxed-icon-icon">
          {icon}
      </div>
    </div>
  );
};


const BoxedIconLights = ({ state, icon, color: propColor }) => {
  const [color, setColor] = useState(sessionStorage.getItem('color') || 'white');

  useEffect(() => {
    if (propColor && propColor !== color) {
      setColor(propColor);
      sessionStorage.setItem('color', propColor);
    }
  }, [propColor, color]);

  const isWhite = (color) => {
    return ( 
      color === 'rgb(255,255,255)'
    );
  }

  const className = isWhite(color) 
    ? 'boxed-icon boxed-icon-white'
    : 'boxed-icon boxed-icon-lights';

  return (
    <div className={className}>
      <BoxedIcon state={state} icon={icon} color={color} />
    </div>
  );
};


export { BoxedIcon, BoxedIconLights };
