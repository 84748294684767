const environments = {
  'STAGING': 'https://staging-api.tinyfar.ms',
  'PRODUCTION': 'https://api.tinyfar.ms',
  'DEFAULT': 'http://localhost:11111'
};

const apiEndpoint = environments[process.env.REACT_APP_ENV || 'STAGING'];


const config = { apiEndpoint };

export default config;