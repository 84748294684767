import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const exportToXLS = (data, fileName = 'export.xlsx') => {
  let worksheetData;

  if (Array.isArray(data)) {
    // If data is already an array, use it directly
    worksheetData = data;
  } else if (typeof data === 'object') {
    // If data is an object, convert it to an array of objects
    worksheetData = Object.keys(data).flatMap(key => 
      data[key].map(item => ({
        type: key,
        ...item
      }))
    );
  } else {
    console.error('Unsupported data format for XLS export');
    return;
  }

  const ws = XLSX.utils.json_to_sheet(worksheetData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, fileName);
};

export const exportToPDF = async (elementIds, fileName = 'export.pdf') => {
  const pdf = new jsPDF('p', 'mm', 'a4');
  let yOffset = 10; // Start with a small top margin

  for (const elementId of elementIds) {
    const element = document.getElementById(elementId);
    const chartWrapper = element.closest('.chart-wrapper');

    if (chartWrapper) {
      const canvas = await html2canvas(chartWrapper, {
        scale: 2,
        logging: false,
        useCORS: true
      });
      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (yOffset + pdfHeight > pdf.internal.pageSize.getHeight() - 10) {
        pdf.addPage();
        yOffset = 10;
      }

      pdf.addImage(imgData, 'PNG', 10, yOffset, pdfWidth, pdfHeight);
      yOffset += pdfHeight + 10;

      // Check if we need to add a new page for the next chart
      if (yOffset > pdf.internal.pageSize.getHeight() - 10) {
        pdf.addPage();
        yOffset = 10;
      }
    }
  }

  pdf.save(fileName);
};

export const exportToPNG = async (elementIds, fileName = 'export.png') => {
  const canvases = await Promise.all(elementIds.map(elementId => {
    const element = document.getElementById(elementId);
    const chartWrapper = element.closest('.chart-wrapper');
    return html2canvas(chartWrapper, {
      scale: 2,
      logging: false,
      useCORS: true
    });
  }));

  const totalHeight = canvases.reduce((sum, canvas) => sum + canvas.height, 0);
  const maxWidth = Math.max(...canvases.map(canvas => canvas.width));

  const finalCanvas = document.createElement('canvas');
  finalCanvas.width = maxWidth;
  finalCanvas.height = totalHeight;
  const ctx = finalCanvas.getContext('2d');

  let yOffset = 0;
  canvases.forEach(canvas => {
    ctx.drawImage(canvas, 0, yOffset);
    yOffset += canvas.height;
  });

  const link = document.createElement('a');
  link.download = fileName;
  link.href = finalCanvas.toDataURL('image/png');
  link.click();
};
