import 'blocklyCustom/customBlocks';
import * as Blockly from 'blockly/core';
import 'blockly/blocks';
import 'blockly/javascript';

Blockly.Themes.Tinyfarm = Blockly.Theme.defineTheme('custom', {
  'base': Blockly.Themes.Zelos,
  'flyoutBackgroundColour': '#F0F0FF',
  'flyoutForegroundColour': '#5D55D8',
  'blockStyles': {
    'base_blocks': {
      'colourPrimary': '#7870E6',
      'colourTertiary': '#5D55D8',
    },
    'logic_blocks': {
      'colourPrimary': '#CC9E43',
      'colourTertiary': '#AD822B',
    },
    'loop_blocks': {
      'colourPrimary': '#CF5DCA',
      'colourTertiary': '#B04AAB',
    },
    'farm_blocks': {
      'colourPrimary': '#00B868',
      'colourTertiary': '#009454',
    },
  },
});

export const localizedToolboxConfiguration = (t) => ({
  kind: "categoryToolbox",
  contents: [
    {
      kind: "category",
      name: t('blockly.categories.Base'),
      cssconfig: {
        container: 'custom-base-container blocklyToolboxCategory',
      },
      contents: [
        { kind: "block", type: "forever" },
        { kind: "block", type: "on_start" },
        { kind: "block", type: "wait" },
        { kind: "block", type: "pause" },
        { kind: "block", type: "number_input" },
        { kind: "block", type: "random_number" },
        { kind: "block", type: "time_input" },
      ]
    },
    {
      kind: "category",
      name: t('blockly.categories.Logic'),
      cssconfig: {
        container: 'custom-logic-container blocklyToolboxCategory',
      },
      contents: [
        { kind: "block", type: "controls_if" },
        { kind: "block", type: "controls_ifelse" },
        { kind: "block", type: "logic_compare" },
        { kind: "block", type: "logic_operation" },
        { kind: "block", type: "logic_boolean" },
      ],
    },
    {
      kind: "category",
      name: t('blockly.categories.Loops'),
      cssconfig: {
        container: 'custom-loops-container blocklyToolboxCategory',
      },
      contents: [
        { kind: "block", type: "controls_for" },
        { kind: "block", type: "controls_repeat" },
        { kind: "block", type: "controls_while" },
      ],
    },
    {
      kind: "category",
      name: t('blockly.categories.Farm'),
      cssconfig: {
        container: 'custom-farm-container blocklyToolboxCategory',
      },
      contents: [
        { kind: "label", text: "Sensors", webclass: "blockly-label" },
        { kind: "block", type: "co2" },
        { kind: "block", type: "humidity" },
        { kind: "block", type: "temperature" },
        { kind: "block", type: "current_time" },
        { kind: "label", text: "Controls", webclass: "blockly-label" },
        { kind: "block", type: "pump_water" },
        { kind: "block", type: "pump_nutrients" },
        { kind: "block", type: "pump_air" },
        { kind: "block", type: "picture" },
        { kind: "block", type: "set_color_light" },
        { kind: "block", type: "set_light" },
      ],
    },
  ],
});

export const workspaceConfiguration = {
  renderer: 'zelos',
  theme: Blockly.Themes.Tinyfarm,
  media: '/blockly/media/',
  trashcan: true,
  move: {
    scrollbars: {
      horizontal: true,
      vertical: true
    },
    drag: true,
    wheel: true
  },
  grid: {
    spacing: 20,
    length: 2,
    colour: 'var(--purple-20)',
    snap: true
  },
  maxBlocks: 40
};
