import React, { useEffect } from 'react'
import './settings.scss'
import {SettingItem} from 'components/pages/settings/settingItem';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Settings = () => {
  useEffect(() => {
    // Scroll to the top of the page
    document.getElementById("content").scrollTo(0, 0);
  }, []);
  const { t } = useTranslation('settings');
  const {deviceName} = useSelector(state => state.auth);

  return (
    <>
    <div className='main'>
      <div className='settings-section'>
        <h4 className="settings-section-heading">
          {t('header_tinyfarm')}
        </h4>
        <div className='settings-section-wrapper'>
          <SettingItem
            title={t('name')}
            currentValue={deviceName}
          />
          {/* <SettingItem
            color='green' 
            title={t('pin')} 
            description='' 
            actionType="button" 
            buttonLabel={t('button_reset_pin')}
          /> */}
          <SettingItem
            title={t('update')} 
            description={t('update_text')} 
            actionType="button" 
            buttonLabel={t('button_update')} 
            instruction='update'
          />
        </div>
      </div>
      {/* <div className='settings-section'>
      <h4 className="settings-section-heading">
      {t('header_camera')}
      </h4>
      <div className='settings-section-wrapper'>
      <SettingItem color ='green' title={t('flash')} description = {t('flash_text')} actionType="toggle" buttonLabel='' />
      </div>
      </div> */}
      {/* <div className='settings-section'>
      <h4 className="settings-section-heading">
      {t('header_data')}
      </h4>
      <div className='settings-section-wrapper'>
      <SettingItem color ='green' title={t('export')} description = {t('export_text')} actionType="button" buttonLabel={t('button_export')} />
      </div>
      </div> */}
      <div className='settings-section'>
        <h4 className="settings-section-heading">
          {t('header_connectivity')}
        </h4>
        <div className='settings-section-wrapper'>
          <SettingItem
            title={t('wifi')} 
            description={t('wifi_text')} 
            actionType="button" 
            buttonLabel={t('button_wifi')} 
            isLogout={true}
            isWarning={true}
            instruction='wifi_reset'  
          />
        </div>
      </div>
      <div className='settings-section settings-section-red'>
        <h4 className="settings-section-heading">
          {t('header_danger')}
        </h4>
        <div className='settings-section-wrapper'>
          {/* <SettingItem
            color='red'
            title={t('clear_data')}
            description={t('clear_data_text')}
            actionType="button"
            buttonLabel={t('button_clear_data')}
            /> */}
          <SettingItem
            color='red' 
            title={t('reset_tinyfarm')} 
            description = {t('reset_tinyfarm_text')} 
            actionType="button" 
            buttonLabel={t('button_reset_tinyfarm')} 
            isWarning={true}
            isLogout={true}
            instruction='device_reset'
          />
          <SettingItem
            color='red' 
            title={t('factory_reset_tinyfarm')} 
            description = {t('factory_reset_tinyfarm_text')} 
            actionType="button" 
            buttonLabel={t('button_factoryreset_tinyfarm')} 
            isWarning={true}
            isLogout={true}
            instruction='factory_reset'
          />
        </div>
      </div>
    </div>
    </>
  )
}

export default Settings;