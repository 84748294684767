import React from 'react';
import Button from 'components/global/buttons';
import { useTranslation } from 'react-i18next';
const ActionBar = ({ onOpenClick, onExamplesClick, onSaveClick, onUndo, onRedo, onClear, isLocked, onLockClick, onRunCommand, isCountdownVisible }) => {
  const { t } = useTranslation('programming');

  return (
    <div className="action-bar">
      <div className="action-buttons">
        <div className="button-group">
          <Button type="secondary" color="purple" iconFirst="📂" label={t('buttons.open')} collapseWidth={836} onClick={onOpenClick}/>
          <Button type="secondary" color="purple" iconFirst="🌱" label={t('buttons.examples')} collapseWidth={836} onClick={onExamplesClick}/>
          <Button type="secondary" color="purple" iconFirst="💾" label={t('buttons.save')} collapseWidth={836} onClick={onSaveClick}/>
        </div>
        <div className="button-group">
          <Button type="secondary" color="purple" iconFirst="↩️" label={t('buttons.undo')} collapseWidth={1084} onClick={() => onUndo(false)} />
          <Button type="secondary" color="purple" iconFirst="↪️" label={t('buttons.redo')} collapseWidth={1084} onClick={() => onRedo(true)} />
          <Button type="secondary" color="purple" iconFirst="🗑" label={t('buttons.clear')} collapseWidth={1084} onClick={onClear} />
        </div>
      </div>
      <div className="right-buttons">
        {isCountdownVisible ? (
          <Button
            type="primary"
            color="green"
            iconFirst="⏰"
            countdown={true}
            disabled
          />
        ) : (
          <>
            <Button 
              type={isLocked ? "primary" : "secondary"} 
              color="purple" 
              iconFirst={isLocked ? "🔒" : "🔓"}  
              collapseWidth={1280} 
              onClick={onLockClick} 
              className="lock-tinyfarm-btn"
              label={isLocked ? t('buttons.unlock') : ""} 
            />
            <Button
              type="primary"
              color="green"
              iconFirst="⬆️"
              label={t('buttons.runInTinyfarm')}          
              className="run-tinyfarm-btn"
              onClick={onRunCommand} 
              disabled={isLocked}
            />    
          </>
        )}
      </div>
    </div>
  );
};

export default ActionBar;
