import './navbar.scss';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';
import { getBitmap } from 'components/functions/navbar/navbarEndpointFunctions';
import Button from './global/buttons'
import LanguagePicker from 'components/pages/navbar/languagePicker';
import { Link } from 'react-router-dom';
import {ReactComponent as Logo} from 'assets/global/logo-icon.svg';
import Clock from 'react-live-clock';
import {ReactComponent as ChevronLeft} from 'assets/global/chevron-left-fill-medium.svg';
import {ReactComponent as Menu} from 'assets/global/menu-stroke-large.svg';
import {ReactComponent as Cross} from 'assets/global/cross-stroke-large.svg';
import Tooltip from './global/tooltips';
import useWindowWidth from 'components/functions/utils/useWindowWidth';

const Navbar = () => {
  const { t } = useTranslation('global');
  const { accessToken, deviceId, deviceName } =  useSelector(state => state.auth);
  const { isMobile } = useWindowWidth();
  const buttonType = (path) => {
    const currentPath = window.location.pathname.slice(1);
    if (currentPath === path) {
      return 'primary';
    } else if (currentPath.includes(path) && path !== "") {
      return 'primary';
    } else {
      return 'secondary';
    }
  };

  const [bitmapData, setBitmapData] = useState(null);
  const [retries, setRetries] = useState(0);
  
  const RETRY_DELAY = 2000;  
  const MAX_RETRIES = 3;

  useEffect(() => {
    if (!bitmapData) {
      if (retries < MAX_RETRIES) {
        if (!bitmapData) fetchBitmap();
      } else {
        console.error("Max retry attempts reached.");
      }
    }
  }, [bitmapData, retries]);

  const fetchBitmap = async () => {
    try {
      if (accessToken && accessToken.split('.').length === 3) {
        const response = await getBitmap(accessToken, deviceName);
        setBitmapData(response);
      }
    } catch (error) {
      console.error("Failed fetching bitmap:", error);
      retry();
    }
  };

  const retry = () => {
    setTimeout(() => {
      setRetries(prev => prev + 1);
    }, RETRY_DELAY);
  };

  // Handle navbar collapsed state
  const [addCollapsed, setCollapsed] = useState(false);
  const handleCollapser = () => {
      setCollapsed(!addCollapsed);
  };

  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  // const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  // Toggle nav menu visibility
  const toggleNav = () => {
    // If language menu is open, we just want to switch back to the main menu without closing the entire menu
    // if (isLanguageOpen) {
    //   setIsLanguageOpen(false);
    //   setIsNavOpen(true);
    // } else {
      setIsNavOpen(!isNavOpen);
    //   setIsMenuOpen(!isMenuOpen);
    // }
  };

  return (
    <>
    {!isMobile ? (
      <div id="navbar" className={`${addCollapsed ? 'collapsed' : ''}`}>
        <div className="navbar-collapser" onClick={handleCollapser}>
          <ChevronLeft/>
        </div>
        <div className="items-center justify-center nav-link-list">
          <Tooltip
            color="white"
            position="right"
            alignment="top"
            customContent={
              <div className="navbar-profile flex flex-col justify-center items-center">
                <Link to="/" className="navbar-profile-avatar">
                  {bitmapData ? (
                    <img src={bitmapData} className="bitmap-image" alt="Bitmap" />
                  ) : (
                    <Logo />
                  )}
                </Link>
                <div className="flex flex-col justify-center items-center navbar-profile-text">
                  <div className="tinyfarm-name">{deviceName || t('loading')}</div>
                  <div className="tinyfarm-id small">{t('navbar_tinyfarmID')}: {deviceId || t('loading')}</div>
                  <Clock className="small" format={'HH:mm:ss'} ticking={true} />
                </div>
              </div>
            }
          >
            <div className="flex w-full navbar-profile justify-center align-center">
              <Link to="/" className="navbar-profile-avatar">
                {bitmapData ? (
                  <img src={bitmapData} className="bitmap-image" alt="Bitmap" />
                ) : (
                  <Logo />
                )}
              </Link>
              <div className="flex flex-col justify-center items-center navbar-profile-text">
                <div className="tinyfarm-name">{deviceName || t('loading')}</div>
                <div className="tinyfarm-id small">{t('navbar_tinyfarmID')}: {deviceId || t('loading')}</div>
                <Clock className="small" format={'HH:mm:ss'} ticking={true} />
              </div>
            </div>
          </Tooltip>
          <Link to="/" className="nav-link">
            <Tooltip color="purple" body={t('pageTitle.controlPanel')} position="right">
              <Button type={buttonType("")} color="purple" label={t('pageTitle.controlPanel')} iconFirst={t('pageTitle.controlPanelIcon')} />
            </Tooltip>
          </Link>
          <Link to="/programming" className="nav-link">
            <Tooltip color="purple" body={t('pageTitle.programming')} position="right">
              <Button type={buttonType("programming")} color="purple" label={t('pageTitle.programming')} iconFirst={t('pageTitle.programmingIcon')} />
            </Tooltip>
          </Link>
          <Link to="/analytics" className="nav-link">
            <Tooltip color="purple" body={t('pageTitle.analytics')} position="right">
              <Button type={buttonType("analytics")} color="purple" label={t('pageTitle.analytics')} iconFirst={t('pageTitle.analyticsIcon')} />
            </Tooltip>
          </Link>
          <Link to="/gallery" className="nav-link">
            <Tooltip color="purple" body={t('pageTitle.gallery')} position="right">
              <Button type={buttonType("gallery")} color="purple" label={t('pageTitle.gallery')} iconFirst={t('pageTitle.galleryIcon')}/>
            </Tooltip>
          </Link>
          <Link to="/learning" className="nav-link">
            <Tooltip color="purple" body={t('pageTitle.learning')} position="right">
              <Button type={buttonType("learning")} color="purple" label={t('pageTitle.learning')} iconFirst={t('pageTitle.learningIcon')} />
            </Tooltip>
          </Link>
        </div>
        <div className="items-center justify-center nav-link-list admin-links">
          <LanguagePicker type="secondary" color="purple"/>
          <Link to="/settings" className="nav-link">
            <Tooltip color="purple" body={t('pageTitle.settings')} position="right">
              <Button type={buttonType("settings")} color="purple" label={t('pageTitle.settings')} iconFirst={t('pageTitle.settingsIcon')} />
            </Tooltip>
          </Link>
          <Link to="/logout" className="nav-link">
            <Tooltip color="purple" body={t('pageTitle.logout')} position="right">
              <Button type={buttonType("logout")} color="purple" label={t('pageTitle.logout')} iconFirst={t('pageTitle.logoutIcon')} />
            </Tooltip>
          </Link>
        </div>
      </div>
    ) : (
      <div id="navbar-mobile" className={`${isNavOpen ? 'nav-open' : ''}`}>
        <div className="navbar-profile flex w-full justify-center items-center gap-16">
          <div className='flex w-full justify-center items-center gap-8'>
            <div className='flex w-full justify-start items-center gap-8'>
              <Link to="/" className="navbar-profile-avatar">
                {bitmapData ? (
                  <img src={bitmapData} className="bitmap-image" alt="Bitmap" />
                ) : (
                  <Logo />
                )}
              </Link>
              <div className="navbar-profile-text flex flex-col justify-center items-start">
                <div className="tinyfarm-name bold">{deviceName || t('loading')}</div>
                <div className="tinyfarm-id xsmall">{t('navbar_tinyfarmID')}: {deviceId || t('loading')}</div>
              </div>
            </div>
            <LanguagePicker type="primary" color="purple" small/>
          </div>
          {!isNavOpen ? (
            <Menu className="menu-button" onClick={toggleNav}/>
          ) : (
            <Cross className="menu-button" onClick={toggleNav}/>
          )}
        </div>
        <div className="nav-link-menu">
          <div className="nav-link-list">
            <Link to="/" className="nav-link" onClick={toggleNav}>
              <Button type="primary" color="purple" label={t('pageTitle.controlPanel')} iconFirst={t('pageTitle.controlPanelIcon')} />
            </Link>
            <Link to="/programming" className="nav-link" onClick={toggleNav}>
              <Button type="primary" color="purple" label={t('pageTitle.programming')} iconFirst={t('pageTitle.programmingIcon')} />
            </Link>
            <Link to="/analytics" className="nav-link" onClick={toggleNav}>
              <Button type="primary" color="purple" label={t('pageTitle.analytics')} iconFirst={t('pageTitle.analyticsIcon')} />
            </Link>
            <Link to="/gallery" className="nav-link" onClick={toggleNav}>
              <Button type="primary" color="purple" label={t('pageTitle.gallery')} iconFirst={t('pageTitle.galleryIcon')}/>
            </Link>
            <Link to="/learning" className="nav-link" onClick={toggleNav}>
              <Button type="primary" color="purple" label={t('pageTitle.learning')} iconFirst={t('pageTitle.learningIcon')} />
            </Link>
          </div>
          <div className="nav-link-list items-center justify-center admin-links">
            <Link to="/settings" className="nav-link" onClick={toggleNav}>
              <Button type="primary" color="purple" label={t('pageTitle.settings')} iconFirst={t('pageTitle.settingsIcon')} />
            </Link>
            <Link to="/logout" className="nav-link" onClick={toggleNav}>
              <Button type="primary" color="purple" label={t('pageTitle.logout')} iconFirst={t('pageTitle.logoutIcon')} />
            </Link>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default Navbar;
