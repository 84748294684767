import './buttons.scss';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowWidth from 'components/functions/utils/useWindowWidth';

const Button = ({
  type,
  color,
  small,
  iconFirst,
  label,
  iconLast,
  onClick,
  id,
  className,
  customStyles,
  collapseWidth,
  disabled,
  selectable,
  countdown,
  countdownSeconds = 10,
  selected,  // Add this prop
}) => {
  const { t } = useTranslation('programming');
  const [remainingTime, setRemainingTime] = useState(countdownSeconds);
  const { windowWidth, isMobile, isTablet } = useWindowWidth();

  useEffect(() => {
    if (countdown) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime > 1) {
            return prevTime - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown && remainingTime === 0) {
      setRemainingTime(countdownSeconds); // reset to initial countdown time when it reaches 0
    }
  }, [countdown, remainingTime, countdownSeconds]);

  let isCollapsed = false;
  if (typeof collapseWidth === 'number') {
    isCollapsed = windowWidth < collapseWidth;
  } else if (collapseWidth === 'mobile' && isMobile) {
    isCollapsed = true;
  } else if (collapseWidth === 'tablet' && isTablet) {
    isCollapsed = true;
  }

  const colorClass = color ? `btn-${color}` : '';
  const typeClass = type ? `btn-${type}` : '';
  const iconClass = !label && !countdown ? 'btn-icon' : '';
  const smallClass = small ? 'btn-small' : '';
  const collapsedClass = isCollapsed ? 'btn-collapsed' : '';
  const customClass = className ? ` ${className}` : '';
  const disabledClass = disabled ? 'btn-disabled' : '';
  const selectedClass = selectable && selected ? ' btn-selected' : ''; // Use `selected` prop

  const buttonClass = [
    'btn',
    colorClass,
    typeClass,
    iconClass,
    smallClass,
    collapsedClass,
    customClass,
    disabledClass,
    selectedClass,
  ]
    .filter(Boolean)
    .join(' ');

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const countdownLabel = countdown
    ? t('buttons.seconds', { seconds: remainingTime })
    : label;

  return (
    <button
      id={id || ''}
      className={buttonClass}
      onClick={handleClick}
      style={customStyles}
      disabled={disabled || countdown}
    >
      <div className="btn-content">
        {iconFirst && <span className="btn-icon-first">{iconFirst}</span>}
        {countdownLabel && <span className="btn-label">{countdownLabel}</span>}
        {iconLast && <span className="btn-icon-last">{iconLast}</span>}
      </div>
    </button>
  );
};

export default Button;
