// src/components/global/PinInput.js
import React, { useRef } from 'react';
import '../login.scss'; 
import './pinInput.scss';
const PinInput = ({ pin, setPin, loading, t }) => {
  const pinInputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleInputFocus = (e) => {
    e.target.select();
  };

  const handlePinChange = (index) => (e) => {
    if (e.target.value === '' || (e.target.value.length === 1 && /^[0-9]$/.test(e.target.value))) {
      setPin((prevPin) => {
        const newPin = [...prevPin];
        newPin[index] = e.target.value;
        return newPin;
      });
      if (e.target.nextSibling) {
        e.target.nextSibling.focus();
      }
    }
  };

  const handleBackspace = (index) => (e) => {
    if (e.key === "Backspace") {
      if (pin[index] !== "") {
        setPin(prevPin => {
          const newPin = [...prevPin];
          newPin[index] = '';
          return newPin;
        });
      } else {
        e.preventDefault();
        if (e.target.previousSibling) {
          e.target.previousSibling.focus();
        }
      }
    }
  };

  return (
    <div className="pin">
      <div className="login-pin-inputs login-inputs">
        {pin.map((digit, i) => (
          <input
            key={i}
            type="number"
            id={`pin-${i}`} // Make the id unique by appending the index.
            ref={pinInputRefs[i]}
            value={digit}
            onChange={handlePinChange(i)}
            onKeyDown={handleBackspace(i)}
            onFocus={handleInputFocus}
            maxLength="1"
            className="login-input"
            required
            disabled={loading}
          />
        ))}
      </div>
      </div>
  );
};

export default PinInput;
