(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("blockly/core"), require("blockly/javascript"), require("blockly/dart"), require("blockly/lua"), require("blockly/php"), require("blockly/python"));
	else if(typeof define === 'function' && define.amd)
		define(["blockly/core", "blockly/javascript", "blockly/dart", "blockly/lua", "blockly/php", "blockly/python"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("blockly/core"), require("blockly/javascript"), require("blockly/dart"), require("blockly/lua"), require("blockly/php"), require("blockly/python")) : factory(root["Blockly"], root["Blockly.JavaScript"], root["Blockly.Dart"], root["Blockly.Lua"], root["Blockly.PHP"], root["Blockly.Python"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__370__, __WEBPACK_EXTERNAL_MODULE__127__, __WEBPACK_EXTERNAL_MODULE__379__, __WEBPACK_EXTERNAL_MODULE__157__, __WEBPACK_EXTERNAL_MODULE__537__, __WEBPACK_EXTERNAL_MODULE__557__) => {
return 