import config from 'config';
import { formatISO } from 'date-fns';


export const saveBlocklyProgram = async (name, blocklyJson, accessToken, active, is_autosaved) => {
  const url = `${config.apiEndpoint}/devices/blockly`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ // Sending data in request body
        program: blocklyJson,
        title: name,
        active: active,
        is_autosaved: is_autosaved
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to save code: ${response.statusText}`);
    }
  
    return response;

  } catch (error) {
    console.error('Error:', error);
  }
};

export const lockBlockly = async (accessToken, lockDuration, lock_code) => {
  const url = `${config.apiEndpoint}/devices/blockly`;

  // Calculate lock_until based on lockDuration
  let lock_until;
  const now = new Date();

  switch (lockDuration) {
    case 1: // One hour
      lock_until = new Date(now.getTime() + 1 * 60 * 60 * 1000);
      break;
    case 2: // One day
      lock_until = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      break;
    case 3: // One week
      lock_until = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
      break;
    default:
      throw new Error('Invalid lock duration');
  }

  // Format lock_until to "YYYY-MM-DDTHH:mm:ssZ"
  const formattedLockUntil = formatISO(lock_until);
  console.log(formattedLockUntil);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ 
        lock_until: formattedLockUntil,
        lock_code: lock_code
      }),
      
    });

    if (!response.ok) {
      throw new Error(`Failed to lock code: ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};
export const unlockBlockly = async (accessToken, lock_code) => {
  const url = `${config.apiEndpoint}/devices/blockly`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ 
        lock_code: lock_code
      }),
    });
    if (response.status === 423) {
      return { status: 423, message: 'Device is locked' };
    } else if (response.ok) {
      return response;
    } else {
      throw new Error(`Failed to unlock code: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error:', error);
    throw new Error(`Error while unlocking: ${error.message}`);
  }
};

export const getLockStatus = async (accessToken) => {
  // Include programs=0 as a query parameter in the URL
  const url = `${config.apiEndpoint}/devices/blockly?programs=0`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to get lock status: ${response.statusText}`);
    }

    const data = await response.json();

    if (data && data.length > 0 && data[0].locked_until) {
      const locked_until = data[0].locked_until;
      
      const [datePart, timePart] = locked_until.split('T');
      const [day, month, year] = datePart.split('-');

      const isoFormattedDate = `${year}-${month}-${day}T${timePart}`;

      const now = new Date();
      const lockDate = new Date(isoFormattedDate);
      const isLocked = lockDate > now;
      return {
        isLocked,
        lockedUntil: isLocked ? isoFormattedDate : null,
      };
    }

    return {
      isLocked: false,
      lockedUntil: null,
    };
  } catch (error) {
    console.error('Error:', error);
    return {
      isLocked: false,
      lockedUntil: null,
    };
  }
};

// Utility function to calculate lock_until time
const getLockUntilTime = () => {
  const now = new Date();
  const lock_until = new Date(now.getTime() + 10 * 1000);
  return formatISO(lock_until);
};

export const sendJsonCommandDevice = async (blocklyJson, xmlJson, autosavetext, accessToken, active) => {
  // Check the lock status first
  const lockStatus = await getLockStatus(accessToken);

  if (lockStatus.isLocked) {
    // Device is locked, return a 423 response equivalent
    console.error('Device is locked until:', lockStatus.lockedUntil);
    return { status: 423, message: `Device is locked until ${lockStatus.lockedUntil}` };
  }

  // If the device is not locked, proceed with sending the command
  const url = `${config.apiEndpoint}/devices/instruction`;
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ 
        value: blocklyJson,
        instruction: 'blockly',
        lock_until: getLockUntilTime(),
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to run code: ${response.statusText}`);
    }

    // Save the Blockly program only if the command was successful
    saveBlocklyProgram(autosavetext, xmlJson, accessToken, active, true);

    return response; 
  } catch (error) {
    console.error('Error:', error);
    throw new Error(`Error while sending command: ${error.message}`);
  }
};

export const sendStopCommandDevice = async (accessToken) => {
  return sendJsonCommandDevice({}, {}, '', accessToken, false);
};

export async function getProgramsJSON(accessToken, amount, running){
  const url = `${config.apiEndpoint}/devices/blockly?programs=${amount}`;
  
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to obtain programs: ${response.statusText}`);
    }

    const data = await response.json();

    if (running) {
      if (data && data.length > 0 && data[0].program !== undefined) {
        const program = data[0].program;
        return program;
      } else {
        console.log("No running programs found.");
        return null;
      }
    } else {
      return data;
    }

  } catch (error) {
    console.error('Error:', error);
  }
}
