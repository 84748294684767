import './pageTitle.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PageTitle = () => {
    const currentPath = window.location.pathname.slice(1);
    const { t } = useTranslation('global');
    const path = currentPath == "" ? "controlPanel" : currentPath;
    
    return (
        <div className='page-title'>
            <span className='page-title-icon'>{t(`pageTitle.${path}Icon`)}</span>
            <div className='page-title-text bold'>{t(`pageTitle.${path}`)}</div>
        </div>
    );
};

export default PageTitle;