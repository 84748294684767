import React from 'react';

const FrameSVG = ({color, intensity}) => {
const opacity = intensity / 100;
const isWhite = color.toLowerCase() === "#ffffff" || color.toLowerCase() === "white" || color === "rgb(255,255,255)";
return(
<svg className="virtual-farm-svg" width="706" height="806" viewBox="0 0 706 806" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#svg_boundaries)">
        <g id="shadow" style={{ mixBlendMode: 'multiply' }} filter="url(#shadow_blur)">
            <path fillRule="evenodd" clipRule="evenodd" d="M702.09 276.444C703.33 274.84 703.173 272.561 701.723 271.142L428.569 3.79316C427.153 2.40736 424.935 2.26601 423.355 3.46085L6.13774 318.853C4.20108 320.317 4.00531 323.153 5.72247 324.869L374.169 693.033C375.874 694.737 378.69 694.557 380.165 692.65L702.09 276.444Z" fill="url(#shadow_gradient)" fillOpacity="0.5" />
        </g>
        {isWhite ? (
        <>
            <mask id="white_lights" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="11" y="706" width="359" height="100">
                <path d="M11 806L68.3851 706H311.639L369.024 806H11Z" fill="url(#white_lights_radial_gradient)" />
            </mask>
            <g mask="url(#white_lights)">
                <path d="M11 806L68.3851 706H311.639L369.024 806H11Z" fill={color} opacity={opacity} />
            </g>
        </>
        ) : (
        <>
            <mask id="color_lights" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="11" y="706" width="359" height="100">
                <path d="M11 806L68.3851 706H311.639L369.024 806H11Z" fill="url(#color_lights_radial_gradient)" />
            </mask>
            <g mask="url(#color_lights)">
                <path d="M11 806L68.3851 706H311.639L369.024 806H11Z" fill={color} opacity={opacity} />
            </g>
        </>
        )}
        <path id="window" d="M60.3842 699H320.384C320.384 703.418 316.802 707 312.384 707H68.3842C63.9659 707 60.3842 703.418 60.3842 699Z" fill="url(#window_paint)" />
        <path id="body_bg" fillRule="evenodd" clipRule="evenodd" d="M376 699.412C378.209 699.412 380 697.621 380 695.412V323.412C380 321.203 378.209 319.412 376 319.412L4 319.412C1.79086 319.412 0 321.203 0 323.412L0 695.412C0 697.621 1.79085 699.412 3.99999 699.412H376Z" fill="url(#body_bg_gradient)" />
        <path id="body_fg" fillRule="evenodd" clipRule="evenodd" d="M376 699.412C378.209 699.412 380 697.621 380 695.412V323.412C380 321.203 378.209 319.412 376 319.412L4 319.412C1.79086 319.412 0 321.203 0 323.412L0 695.412C0 697.621 1.79085 699.412 3.99999 699.412H376Z" fill="url(#body_fg_gradient)" fillOpacity="0.5" />
        <path id="body_border" d="M379 695.412C379 697.069 377.657 698.412 376 698.412H3.99999C2.34314 698.412 1 697.069 1 695.412V323.412C1 321.755 2.34314 320.412 4 320.412H376C377.657 320.412 379 321.755 379 323.412V695.412Z" stroke="url(#body_border_gradient)" strokeOpacity="0.8" strokeWidth="2" />
    </g>
    <defs>
        <filter id="shadow_blur" x="-3.44965" y="-5.34888" width="714.375" height="707.553" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="backgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="backgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_1346_4526" />
        </filter>
        <radialGradient id="shadow_gradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(185.434 512.384) rotate(-44.978) scale(459.874 493.026)">
            <stop stopColor="#746CE5" />
            <stop offset="1" stopColor="#746CE5" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="color_lights_radial_gradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.012 518.5) rotate(90) scale(287.5 287.5)">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="white_lights_radial_gradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.012 518.5) rotate(90) scale(287.5 287.5)">
            <stop offset="0.84" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="window_paint" x1="190.384" y1="699" x2="190.766" y2="712.388" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1E322B" />
            <stop offset="1" stopColor="#808080" />
        </linearGradient>
        <linearGradient id="body_bg_gradient" x1="222.67" y1="319.412" x2="222.67" y2="699.412" gradientUnits="userSpaceOnUse">
            <stop stopColor="#E6E5FA" />
            <stop offset="0.05" stopColor="white" />
            <stop offset="0.95" stopColor="white" />
            <stop offset="1" stopColor="#E6E5FA" />
        </linearGradient>
        <linearGradient id="body_fg_gradient" x1="380.274" y1="319.87" x2="-1.27336" y2="697.868" gradientUnits="userSpaceOnUse">
            <stop offset="0.25" stopColor="#746CE5" stopOpacity="0.5" />
            <stop offset="0.58" stopColor="white" stopOpacity="0.7" />
        </linearGradient>
        <linearGradient id="body_border_gradient" x1="380.274" y1="319.87" x2="-1.27336" y2="697.868" gradientUnits="userSpaceOnUse">
            <stop offset="0.25" stopColor="#B9B5F2" stopOpacity="0.5" />
            <stop offset="0.58" stopColor="#E6E5FA" />
        </linearGradient>
        <clipPath id="svg_boundaries">
            <rect width="706" height="806" fill="white" />
        </clipPath>
    </defs>
</svg>
);
};
export default FrameSVG;

