import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { logOut } from 'redux/slices/authSlice'; 
import LoadingSpinner from 'components/global/loadingSpinner';

const Logout = () => {
  const { t } = useTranslation('global');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Dispatch the logout action
    dispatch(logOut());
    navigate('/login');
  }, [dispatch, navigate]);

  return (
    <LoadingSpinner>
      <h4>{t('logout', { ns: 'global' })}</h4>
    </LoadingSpinner>
  );
};

export default Logout;
