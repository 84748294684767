import './courseBlock.scss';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BoxedIcon } from 'components/global/boxedicon';
import {ReactComponent as ChevronRight} from 'assets/global/chevron-right-stroke-medium.svg';
import useWindowWidth from 'components/functions/utils/useWindowWidth';
import Button from 'components/global/buttons';
import { ReactComponent as Download } from 'assets/global/download-stroke-medium.svg';

const CourseBlock = ({
    icon,
    courseName,
    courseTitle,
    courseDescription,
    courseLink,
    coursePdf,
    color
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation('learning');
    const { isMobile } = useWindowWidth();

    const handleRedirect = () => {
        console.log("navigating to course");
        navigate(courseLink);
    };

    return (
        <div className={`course-block-wrapper course-block-${color}`} onClick={!isMobile ? handleRedirect : () => {}}>
            <BoxedIcon state='idle' icon={icon} className={!isMobile ? "large" : ""}/>
            <div className="course-block-content flex-col align-start justify-center">
                <div className="preheader m0 text-green">{courseTitle}</div>
                <div className="h4 m0 text-purple">{courseName}</div>
                <div className="small">{courseDescription}</div>
            </div>
            {!isMobile ? 
                <ChevronRight className="course-block-chevron"/>
            : 
                <Button
                    type="primary"
                    color="green"
                    iconFirst={<Download/>}
                    label={t("download")}
                    onClick={handleRedirect}
                />
            }
        </div>
    );
};

export default CourseBlock;
