import './controlElement.scss'
import React, {useState, useEffect} from 'react';
import {IntensitySlider, ColorSlider} from './slider.js';
import { CombinedSelector, NumberInput, StaticInput, Dropdown } from 'components/global/selectors';
import { BoxedIconLights } from 'components/global/boxedicon.js';
import {ReactComponent as WhiteLight} from 'assets/controls/lights-white.svg';
import {ReactComponent as RainbowLight} from 'assets/controls/lights-rainbow.svg';
import Toggle from 'components/global/toggle.js';
import {InfoCircleActuator} from 'components/global/infoCircle';
import {sendInstruction} from 'components/functions/controls/controlsEndpointFunctions';
import { nmToRgb } from 'components/functions/utils/light';
import { useTranslation } from 'react-i18next';
import useWindowWidth from 'components/functions/utils/useWindowWidth';

// redux logic
import { useDispatch, useSelector } from 'react-redux';
import {
  setIntensity,
  setCurrentColor,
  setWavelength,
  setIsColor,
  setIsToggleOn,
  setSelectedIndex
} from 'redux/slices/colorsSlice';

const ControlLight = ({ onToastChange }) => {
  const { t } = useTranslation('controls');
  const { isMobile } = useWindowWidth();
  
  // getting initial states from redux 
  const { accessToken } = useSelector(state => state.auth);
  const colorsState = useSelector(state => state.colors);
  const {
    intensity,
    currentColor,
    wavelength,
    isColor,
    isToggleOn,
    selectedIndex
  } = colorsState;
  const [wavelengthLocal, setWavelengthLocal] = useState(() => {
    const storedWavelength = sessionStorage.getItem('wavelengthLocal');
    return storedWavelength !== null ? parseInt(storedWavelength, 10) : wavelength;
  });
  const [intensityLocal, setIntensityLocal] = useState(() => {
    const storedIntensity = sessionStorage.getItem('intensityLocal');
    return storedIntensity !== null ? parseInt(storedIntensity, 10) : intensity;
  });
  const [isColorLocal, setIsColorLocal] = useState(isColor);
  const [isToggleOnLocal, setIsToggleOnLocal] = useState(isToggleOn);

  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.setItem('wavelengthLocal', wavelengthLocal);
  }, [wavelengthLocal]);

  useEffect(() => {
    sessionStorage.setItem('intensityLocal', intensityLocal);
  }, [intensityLocal]);


  const colors = [
    { svg: <WhiteLight />, label: t("colors.colors_white") },        
    { svg: <RainbowLight />, label: t("colors.colors_all") }
  ];

  const colorWhite = 'rgb(255,255,255)';

  const handleColorChange = (newValue) => {
    setWavelengthLocal(newValue);
    dispatch(setWavelength(newValue));
    let newColor = isColorLocal ? nmToRgb(newValue) : colorWhite;
    dispatch(setCurrentColor(newColor));
    if (isToggleOnLocal) {
      sendColorInstructionWithToast(newColor, intensityLocal);
    }
  };

  const handleIntensityChange = (newValue) => {
    setIntensityLocal(newValue);
    dispatch(setIntensity(newValue));
    if (isToggleOnLocal) {
      sendColorInstructionWithToast(currentColor, newValue);
    }
  };

  const handleDropdownChange = (_, selectedIndexDropdown) => {
    const selectedIndexInt = parseInt(selectedIndexDropdown);
    if (selectedIndexInt!== selectedIndex) { 
      dispatch(setSelectedIndex(selectedIndexInt));
      const isColorSelected = selectedIndexInt === 1;
      setIsColorLocal(isColorSelected);
      dispatch(setIsColor(isColorSelected));
      let newColor = isColorSelected? nmToRgb(wavelengthLocal) : colorWhite;
      dispatch(setCurrentColor(newColor));
      if (isToggleOnLocal) {
        sendColorInstructionWithToast(newColor, intensityLocal);
      }
    }
  };

  const handleToggleChange = (toggleState) => {
    if (toggleState !== isToggleOnLocal) {
      dispatch(setIsToggleOn(toggleState));
      setIsToggleOnLocal(toggleState);
      if (toggleState) {
        sendColorInstructionWithToast(currentColor, intensityLocal);
      } else {
        sendColorInstructionWithToast(colorWhite, 0);
      }
    }
  };

  const handleColorMouseUp = (newValue) => {
    setWavelengthLocal(newValue);
    dispatch(setWavelength(newValue));
    let newColor = isColorLocal ? nmToRgb(newValue) : colorWhite;
    dispatch(setCurrentColor(newColor));
    if (isToggleOnLocal) {
      sendColorInstructionWithToast(newColor, intensityLocal);
    }
  };

  const sendColorInstructionWithToast = async (color, intensity) => {
    _sendInstruction(color, intensity);
    console.log("sending command: ", color, intensity);
  };

  const _sendInstruction = async (color, intensity) => {
    if (!accessToken) {
      console.log('Access token not set. Command not sent.');
      return;
    }
    try {
      const rgb = color.match(/\d+/g); 
      const instructionValue = [...rgb, intensity || 0].join(',');
      const response = await sendInstruction('led', instructionValue, accessToken);
      if (response.status === 400) {
        onToastChange(true);
      }
    } catch (err) {
      onToastChange(true);
    }
  };

  const selectors = () => {
    return (
      <div className="selectors">
        <CombinedSelector>
          {[
            <Dropdown defaultIndex={selectedIndex} key="dropdown" items={colors} id={'dropdown-colors'} onChange={handleDropdownChange} />,
            !isColorLocal 
              ? <StaticInput key="static-input" name={t('colors.colors_white')} mobileFill /> 
              : <NumberInput id="num-1" min={400} max={700} key="number-input-nm" value={wavelengthLocal} unit="nm" onChange={handleColorChange} mobileFill />,
            <NumberInput id="num-2" min={0} max={100} key="number-input-percentage" value={intensityLocal} unit="%" onChange={handleIntensityChange} mobileFill />
          ]}
        </CombinedSelector>
        {!isMobile && <Toggle id="light-toggle" disabled={false} label={true} onChange={handleToggleChange} isToggleOn={isToggleOnLocal} />}
      </div>
    );
  };

  return (
    <div className="control-element">
      <div className="control-header">
        <BoxedIconLights icon="💡" state={isToggleOnLocal ? 'active' : 'inactive'} color={currentColor} />
        <div className="control-selectors">
          <div className="control-title">
            <div className="bold">{t('lights')}</div>
            <InfoCircleActuator name={t('lights')} keys_name="lights" icon="💡" />
          </div>
          {!isMobile && selectors()}
        </div>
        {isMobile && <Toggle id="light-toggle" disabled={false} label={true} onChange={handleToggleChange} isToggleOn={isToggleOn} />}
      </div>
      {isMobile && selectors()}
      {isColorLocal && <ColorSlider id="colorSlider" value={wavelengthLocal} min={400} max={700} onMouseUp={handleColorMouseUp} />}
      <IntensitySlider id="simpleSlider" value={intensityLocal} min={0} max={100} onMouseUp={handleIntensityChange} />
    </div>
  );
};

export {ControlLight}