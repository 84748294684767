import React, { useEffect, useState } from 'react';
import { BoxedIcon } from 'components/global/boxedicon';
import { InfoCircleSensor } from 'components/global/infoCircle';
import './sensorBlock.scss';
import {setupSocket, getInitialSensorValue} from 'components/functions/controls/controlsEndpointFunctions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SensorBlock = ({ icon, manualvalue = '', keys_name, name, unit }) => {
    const { t } = useTranslation('controls');
    const {accessToken, deviceId: authDeviceId} = useSelector(state => state.auth);
    const [localDeviceId, setLocalDeviceId] = useState(authDeviceId);
    const [value, setValue] = useState(null);
    const [blockState, setBlockState] = useState('inactive');
    const [displayValue, setDisplayValue] = useState('No data');
 
    useEffect(() => {
      const timerId = setTimeout(() => {
          const storedDeviceId = localStorage.getItem('deviceId');
          if (storedDeviceId) {
              setLocalDeviceId(storedDeviceId); 
          }
      }, 500);

      return () => clearTimeout(timerId);
    }, []);

    useEffect(() => {
      const initialValue = getInitialSensorValue(keys_name);
      if (initialValue) {
          setValue(initialValue);
      }

      if (accessToken && localDeviceId) {
          const socket = setupSocket(accessToken, localDeviceId, {
              [keys_name]: setValue
          });

          return () => {
              socket.disconnect();
          };
      }
    }, [keys_name, accessToken, localDeviceId]);
  
    useEffect(() => {
      let actualValue = manualvalue !== '' ? manualvalue : value;
  
      if (actualValue !== null && actualValue !== undefined && actualValue !== '') {
        setBlockState('active');
        setDisplayValue(actualValue);
        setTimeout(() => {
          setBlockState('idle');
        }, 1000);
      } else {
        setBlockState('inactive');
        setDisplayValue(t('no_data'));
      }
    }, [value, manualvalue]);

      
    const stateClass = `sensor-block-${blockState}`;

    return (
        <div className={`sensor-block ${stateClass}`}>
            <BoxedIcon state={blockState} icon={icon} />
            <div className="sensor-info">
                <div className="sensor-title">
                  <span className="small bold">{name}</span>
                  <InfoCircleSensor name={name} keys_name={keys_name} icon={icon}/>
                </div>
                <div className="sensor-value">
                  <span className="text-value h4 m0">{displayValue}</span>
                  {blockState !== 'inactive' && <span className="text-unit h4 m0">{unit}</span>}
                </div>
            </div>
        </div>
    );
};

export default React.memo(SensorBlock);
