import { format, parseISO } from 'date-fns';
import { enUS, da, lt, de, fr } from 'date-fns/locale';


const getDateLocale = (language) => {
  switch (language) {
    case 'da':
      return da;
    case 'lt':
      return lt;
    case 'de':
      return de;
    case 'fr':
      return fr;
    default:
      return enUS;
  }
};

export const formatLocalizedDate = (dateString, formatString, language) => {
  try {
    const date = parseISO(dateString);
    const dateLocale = getDateLocale(language);
    return format(date, formatString, { locale: dateLocale });
  } catch (error) {
    console.error('Error formatting date:', dateString, error);
    return 'Invalid Date';
  }
};

export function convertDateAndTime(date){

    const dateObject = new Date(date); 
    const formattedDate = dateObject.toLocaleDateString('en-GB');
    const formattedTime = dateObject.toLocaleTimeString('en-GB');
    return formattedDate + " " + formattedTime;
    
  }
export function convertDate(date) {
    const dateObject = new Date(date); 
    const formattedDate = dateObject.toLocaleDateString('en-GB');
    return formattedDate;
}

export const formatDateTime = (dateString) => {
  let formattedDate = "Loading...";
  let formattedTime = "Loading...";

  if (dateString) {
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute, second] = timePart.split(':').map(Number);

    const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    formattedDate = utcDate.toLocaleDateString('en-GB', { timeZone: userTimezone });
    formattedTime = utcDate.toLocaleTimeString('en-GB', { timeZone: userTimezone });
  }

  return { formattedDate, formattedTime };
}

export function timeToUTC(hours, minutes) {
  // Create a Date object with the current date and the provided hours and minutes
  const localTime = new Date();
  localTime.setHours(hours, minutes, 0, 0); // Reset seconds and milliseconds to 0

  // Convert the time to a UTC string
  const utcHours = localTime.getUTCHours().toString().padStart(2, '0');
  const utcMinutes = localTime.getUTCMinutes().toString().padStart(2, '0');
  return utcHours + utcMinutes;
}
