import React, { Children } from 'react';
import './loadingSpinner.scss';
import { CircularProgress } from '@mui/material';

const LoadingSpinner = ({
    className,
    children
}) => {

    return (
        <div className={`loading${className || ""}`}>
            <CircularProgress/>
            {children}
        </div>
    );
}

export default LoadingSpinner;