  import './running.scss';
  import React, {useState} from 'react';
  import {Modal} from 'components/global/modals'
  import Button from 'components/global/buttons'
  import InfoToast from 'components/global/toasts'
  import { jsonToBlocklyWorkspace } from 'components/functions/blockly/utils';
  import { useTranslation } from 'react-i18next';
  import useWindowWidth from 'components/functions/utils/useWindowWidth';

  function ProgramRunning({ programSvgComponent, programRunningJSON, currentWorkspace, onStopProgram, isProgramRunning}) {
    const { t } = useTranslation('programming');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const animationClass = isProgramRunning ? 'is-running' : 'is-stopped';
    const { isMobile } = useWindowWidth();

    const handleViewClick = () => {
      setIsModalOpen(true); 
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const duplicateToWorkspace = () => {
      jsonToBlocklyWorkspace(programRunningJSON, currentWorkspace)
    };

    const handleDuplicateAndClose = () => {
      duplicateToWorkspace();
      handleCloseModal();
    };

    function stopProgram() {
      onStopProgram();
      setIsModalOpen(false);
    };

    return (
      <>
      <InfoToast
        message={t('toasts.programRunning.label')}
        color="green"
        actions={[
          { label: t('toasts.programRunning.btn1'), action: handleViewClick },
          { id: "duplicate", label: t('toasts.programRunning.btn2'), action: handleDuplicateAndClose },
          { label: t('toasts.programRunning.btn3'), color: 'red', action: stopProgram }
        ]}
        preview={{ element: programSvgComponent, action: handleViewClick }}
        classes={animationClass}
      />
      <Modal
          large
          isOpen={isModalOpen}
          heading={t('modals.programRunning.heading')}
          subheading={t('modals.programRunning.subheading' + (isMobile ? '.mobile' : '.default'))}
          actions={[
            { label: t('modals.programRunning.action2'), action: handleDuplicateAndClose, className: "hide-mobile" },
            { label: t('modals.programRunning.action'), color: 'red', action: stopProgram }
          ]}
          onClose={{ label: t('close', { ns: 'global' }), action: handleCloseModal}}
        >
            <div className="program-running-preview">{programSvgComponent}</div>
        </Modal>
      </>        
    );
  }

  export default ProgramRunning;
