import './tooltips.scss';
import React, { useState } from 'react';

const Tooltip = ({
  color,
  heading,
  body,
  reference,
  customContent,
  position,
  alignment,
  id,
  className,
  children
}) => {
  const [isVisible, setIsVisible] = useState(false);
  
  const colorClass = color ? ` tooltip-${color}` : '';
  const positionClass = position ? ` tooltip-${position}` : '';
  const alignmentClass = alignment ? ` tooltip-align-${alignment}` : '';
  const customClass = className ? ` ${className}` : '';

  // Event handlers
  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  return (
    <div id={id || ""} className={`tooltip-container${customClass}`} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {isVisible && (
        <div className={`tooltip${colorClass}${positionClass}${alignmentClass}`}>
          {(heading || body) && 
            <div className="tooltip-text">
              {heading && <p className='tooltip-heading bold'>{heading}</p>}
              {body && <p className="tooltip-body small">{body}</p>}
              {reference && <p className="tooltip-reference xsmall">{reference}</p>}
            </div>
          }
          {customContent}
        </div>
      )}
    </div>
  );
}

export default Tooltip;