import { hoursValidator, minutesValidator, getTextColorBasedOnBackground } from 'components/functions/blockly/utils';
import { nmToRgb } from 'components/functions/utils/light';
import { timeToUTC } from 'components/functions/utils/dates';
import { FieldColour } from '@blockly/field-colour';

export const setupBlocks = (Blockly, t) => {
  
  
  Blockly.Blocks['forever'] = {
    init: function () {
      this.appendStatementInput('STATEMENT')
          .setCheck(null)
          .appendField(t('blockly.blocks.forever'));
      this.setTooltip(t('programming.blockly.tooltips.forever'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "base_blocks",
      });
    },
  };

  Blockly.Blocks['on_start'] = {
    init: function () {
      this.appendStatementInput('STATEMENT')
          .setCheck(null)
          .appendField(t('blockly.blocks.on_start'));
      this.setTooltip(t('programming.blockly.tooltips.on_start'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "base_blocks",
      });
    },
  };

  Blockly.Blocks['wait'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.wait'))
          .appendField(new Blockly.FieldNumber(10, 0), 'DURATION')
          .appendField(new Blockly.FieldDropdown([
            [t('blockly.blocks.milliseconds'), 'MS'],
            [t('blockly.blocks.seconds'), 'S'],
            [t('blockly.blocks.minutes'), 'M'],
            [t('blockly.blocks.hours'), 'H']
          ]), 'TIME_UNIT');
      this.appendStatementInput('STATEMENT')
          .setCheck(null);
      this.setTooltip(t('blockly.tooltips.wait'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "base_blocks",
      });
    },
  };

  Blockly.Blocks['pause'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.pause'))
          .appendField(new Blockly.FieldNumber(10, 0), 'DURATION')
          .appendField(new Blockly.FieldDropdown([
            [t('blockly.blocks.milliseconds'), 'MS'],
            [t('blockly.blocks.seconds'), 'S'],
            [t('blockly.blocks.minutes'), 'M'],
            [t('blockly.blocks.hours'), 'H']
          ]), 'TIME_UNIT');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip(t('programming.blockly.tooltips.pause'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "base_blocks",
      });
    },
  };

  Blockly.Blocks['number_input'] = {
    init: function () {
      this.appendDummyInput()
        .appendField(new Blockly.FieldNumber(0), 'NUMBER_FIELD');
      this.setOutput(true, 'Number');
      this.setTooltip(t('programming.blockly.tooltips.number_input'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "base_blocks",
      });
    },
  };

  Blockly.Blocks['random_number'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.random_number'))
          .appendField(new Blockly.FieldNumber(0), 'X')
          .appendField(t('blockly.blocks.and'))
          .appendField(new Blockly.FieldNumber(999), 'Y');
      this.setOutput(true, 'Number');
      this.setTooltip(t('programming.blockly.tooltips.random_number'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "base_blocks",
      });
    },
  };

  Blockly.Blocks['time_input'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.time_input'))
          .appendField(new Blockly.FieldNumber('23', 0, 23, 1, this.handleHourInputChange.bind(this)), 'HOURS')
          .appendField(':')
          .appendField(new Blockly.FieldNumber('59', 0, 59, 1, this.handleMinuteInputChange.bind(this)), 'MINUTES');
      this.setOutput(true, 'String');
      this.setTooltip(t('blockly.tooltips.time_input'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "base_blocks",
      });

      this.debounceTimer = null;
      this.inputChanges = { 'HOURS': '23', 'MINUTES': '59' };
    },

    handleHourInputChange: function(text) {
      const validatedValue = hoursValidator(text);
      if (validatedValue !== null) {
        this.handleTimeChange(validatedValue, 'HOURS');
      }
      return validatedValue;
    },

    handleMinuteInputChange: function(text) {
      const validatedValue = minutesValidator(text);
      if (validatedValue !== null) {
        this.handleTimeChange(validatedValue, 'MINUTES');
      }
      return validatedValue;
    },

    handleTimeChange: function(newValue, field) {
      this.userHasInteracted = true;
      this.inputChanges[field] = newValue;

      if (this.debounceTimer) clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(this.updateUTCTime.bind(this), 300);
    },

    updateUTCTime: function() {
      if (this.userHasInteracted) {
        let hours = this.getFieldValue('HOURS');
        let minutes = this.getFieldValue('MINUTES');
        hours = hours !== null ? hours.toString().padStart(2, '0') : this.inputChanges['HOURS'];
        minutes = minutes !== null ? minutes.toString().padStart(2, '0') : this.inputChanges['MINUTES'];
        this.data = timeToUTC(hours, minutes);
      }
    },
  };

  Blockly.Blocks['controls_for'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.controls_for_count'))
          .appendField(new Blockly.FieldVariable('i'), 'VAR');
      this.appendDummyInput()
          .appendField(t('blockly.blocks.controls_for_from'))
          .appendField(new Blockly.FieldNumber(0), 'FROM_FIELD');
      this.appendDummyInput()
          .appendField(t('blockly.blocks.controls_for_to'))
          .appendField(new Blockly.FieldNumber(10), 'TO_FIELD');
      this.appendDummyInput()
          .appendField(t('blockly.blocks.controls_for_by'))
          .appendField(new Blockly.FieldNumber(1), 'BY_FIELD');
      this.appendStatementInput('DO')
          .appendField(t('blockly.blocks.controls_do'));
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setTooltip(t('programming.blockly.tooltips.controls_for'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "loop_blocks",
      });
    },
  };

  Blockly.Blocks['controls_while'] = {
    init: function() {
      this.appendValueInput('CONDITION')
          .setCheck('Boolean')
          .appendField(t('blockly.blocks.controls_while'));
      this.appendStatementInput('LOOP')
          .setCheck(null)
          .appendField(t('blockly.blocks.controls_do'));
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip(t('programming.blockly.tooltips.controls_while'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "loop_blocks",
      });
    },
  };

  Blockly.Blocks['cooldown'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("do this")
          .appendField(new Blockly.FieldNumber(1, 1), "TIMES")
          .appendField(new Blockly.FieldDropdown([
            ["times per minute", "minute"],
            ["times per hour", "hour"],
            ["times per day", "day"]
          ], this.handleFrequencyChange.bind(this)), "FREQUENCY_UNIT");

      this.updateStartTimeInput("minute"); // Initial call to set up the block correctly

      this.appendStatementInput("DO")
          .appendField("do");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setStyle('loop_blocks');
    },

    handleFrequencyChange: function(newValue) {
      this.updateStartTimeInput(newValue);
    },

    updateStartTimeInput: function(frequencyUnit) {
      if (this.getInput('START_TIME')) {
        this.removeInput('START_TIME');
      }

      if (frequencyUnit === "minute") {
      } else if (frequencyUnit === "hour") {
        this.appendDummyInput("START_TIME")
            .appendField("starting at the minute: ")
            .appendField(new Blockly.FieldNumber(0, 0, 59, 1), "MINUTE");
      } else if (frequencyUnit === "day") {
        this.appendDummyInput("START_TIME")
            .appendField("starting at the hour: ")
            .appendField(new Blockly.FieldNumber(0, 0, 23, 1), "HOUR")
            .appendField(":")
            .appendField(new Blockly.FieldNumber(0, 0, 59, 1), "MINUTE");
      }
    }
  };

  Blockly.Blocks['pump_water'] = {
    init: function () {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.pump_water'))
          .appendField(new Blockly.FieldNumber(0, 0, 250, 1), 'VOLUME_FIELD')
          .appendField(' ml ')
          .appendField(new Blockly.FieldDropdown([[t('blockly.blocks.in'), 'IN'], [t('blockly.blocks.out'), 'OUT']]), 'DIRECTION');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip(t('blockly.tooltips.pump_water'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
    },
  };

  Blockly.Blocks['pump_nutrients'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.pump_nutrients'))
          .appendField(new Blockly.FieldNumber(0, 0, 100, 1), 'VOLUME_FIELD')
          .appendField(' ml ')
          .appendField(new Blockly.FieldDropdown([[t('blockly.blocks.flowering'), 'FLOWERING'], [t('blockly.blocks.vegetative'), 'VEGETATIVE']]), 'TYPE');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip(t('blockly.tooltips.pump_nutrients'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
    },
  };

  Blockly.Blocks['pump_air'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.pump_air'))
          .appendField(new Blockly.FieldNumber(0, 0, 60, 1), 'VALUE_FIELD')
          .appendField(t('blockly.blocks.seconds'))
          .appendField(new Blockly.FieldDropdown([[t('blockly.blocks.in'), 'IN']]), 'DIRECTION');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip(t('blockly.tooltips.pump_air'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
    },
  };

  Blockly.Blocks['picture'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.picture'));
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip(t('blockly.tooltips.picture'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
    },
  };

  Blockly.Blocks['set_light'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.set_wavelength_light'))
          .appendField(t('blockly.blocks.wavelength'))
          .appendField(new Blockly.FieldNumber(400, 400, 700, 1, this.onWavelengthChange.bind(this)), 'WAVELENGTH_FIELD')
          .appendField('nm')
          .appendField(t('blockly.blocks.intensity'))
          .appendField(new Blockly.FieldNumber(50, 0, 100, 1), 'INTENSITY_FIELD')
          .appendField('%');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip(t('blockly.tooltips.set_wavelength_light'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
      this.setColour(this.getColourForWavelength(400));
    },

    onWavelengthChange: function(newValue) {
      const rgb = nmToRgb(newValue);
      this.setColour(rgb);
      this.updateTextColor();
      return newValue;
    },

    updateTextColor: function() {
      const rgb = this.getColour();
      const textColor = getTextColorBasedOnBackground(rgb);
      const textElements = this.getSvgRoot().querySelectorAll('.blocklyText:not(.blocklyEditableText .blocklyText)');
      textElements.forEach(textElement => {
        textElement.style.fill = textColor;
      });
    },

    getColourForWavelength: function(wavelength) {
      return nmToRgb(wavelength);
    },

    initSvg: function() {
      Blockly.BlockSvg.prototype.initSvg.call(this);
      this.getSvgRoot().classList.add('set-color-light-block');
      this.updateTextColor();
    }
  };

  Blockly.Blocks['example_colour'] = {
    init: function() {
      const field = new FieldColour('#ff4040');
      field.setColours(
          ['#ff4040', '#ff8080', '#ffc0c0',
          '#4040ff', '#8080ff', '#c0c0ff'],
          ['dark pink', 'pink', 'light pink',
          'dark blue', 'blue', 'light blue']);
      field.setColumns(3);
      this.appendDummyInput()
          .appendField('colour:')
          .appendField(field, 'COLOUR');
      this.jsonInit({
        "style": "farm_blocks",
      });
    }
  };
  const colors = [
    '#ffffff', '#ffcccc', '#ff6666', '#ff0000', '#cc0000',
    '#ffcc99', '#ff9966', '#ff9900', '#ff6600', '#cc6600',
    '#ffff99', '#ffff66', '#ffcc66', '#ffcc33', '#cc9933',
    '#ffffcc', '#ffff33', '#ffff00', '#ffcc00', '#999900',
    '#99ff99', '#66ff99', '#33ff33', '#00BF00', '#009900',
    '#99cccc', '#339999', '#33cccc', '#00cccc', '#009999',
    '#ccffff', '#66ffff', '#33ccff', '#3366ff', '#0000FF',
    '#ccccff', '#9999ff', '#6666cc', '#6633ff', '#6600cc',
    '#ffccff', '#ff99ff', '#cc66cc', '#cc33cc', '#993399',
  ];
  const columns = 5;
  Blockly.Blocks['set_color_light'] = {
    init: function() {
      const field = new FieldColour('#ff00ff', this.onColorChange.bind(this), {
        colourOptions: colors,
        colourTitles: colors.map(color => color),  // Optional: titles for each color
        columns: columns
      });

      this.appendDummyInput()
          .appendField(t('blockly.blocks.set_color_light'))
          .appendField(field, 'COLOR_FIELD')
          .appendField(t('blockly.blocks.intensity'))
          .appendField(new Blockly.FieldNumber(50, 0, 100, 1), 'INTENSITY_FIELD')
          .appendField('%');

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip(t('blockly.tooltips.set_color_light'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
    },

    onColorChange: function(newValue) {
      // const textColor = getTextColorBasedOnBackground(newValue); 
      // const textElements = this.getSvgRoot().querySelectorAll('.blocklyText:not(.blocklyEditableText .blocklyText)');
      // textElements.forEach(textElement => {
      //   textElement.style.fill = textColor;
      // });
      return newValue;
    },

    initSvg: function() {
      Blockly.BlockSvg.prototype.initSvg.call(this);
      this.getSvgRoot().classList.add('set-color-light-block');
    }
  };

  Blockly.Blocks['current_time'] = {
    init: function () {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.current_time'));
      this.setOutput(true, 'String');
      this.setTooltip(t('blockly.tooltips.current_time'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
    },
  };

  Blockly.Blocks['co2'] = {
    init: function () {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.co2'));
      this.setOutput(true, 'Number');
      this.setTooltip(t('blockly.tooltips.co2'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
    },
  };

  Blockly.Blocks['humidity'] = {
    init: function () {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.humidity'));
      this.setOutput(true, 'Number');
      this.setTooltip(t('blockly.tooltips.humidity'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
    },
  };

  Blockly.Blocks['temperature'] = {
    init: function () {
      this.appendDummyInput()
          .appendField(t('blockly.blocks.temperature'));
      this.setOutput(true, 'Number');
      this.setTooltip(t('blockly.tooltips.temperature'));
      this.setHelpUrl('');
      this.jsonInit({
        "style": "farm_blocks",
      });
    },
  };
};
