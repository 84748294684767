import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingSpinner from 'components/global/loadingSpinner';

const ProtectedComponent = ({ children }) => {
  const { isAuthenticated, loading, tokenExpired } = useSelector(state => state.auth);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated || tokenExpired) {
    console.log('Redirecting to login due to unauthenticated state');
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedComponent;
