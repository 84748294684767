import React from 'react';
import './learningComponents.css'

const Image = ({ imageURL, legend, source }) => {
    
    return (
        <div className="lesson-image-wrapper">
            <img src={imageURL}/>
            <div className="lesson-image-legend">{legend}</div>
            <div className="lesson-image-source">{source}</div>
        </div>
    );
};

const MultipleImage = ({ URLList }) => {
  return (
    <div className="multiple-images-container">
      {URLList.map((url, index) => (
        <div key={index} className="multiple-image-wrapper">
          <img src={url}/>
        </div>
      ))}
    </div>
  );
};

const Table = ({ table }) => {
  return (
    <div className="table-container">
      <table>
        {table.header && (
          <thead>
            <tr>
              {table.header.map((headerItem, index) => (
                <th key={index}>{headerItem}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {table.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};



const Paragraph = ({ text }) => {
    


    return (
        <div className="lesson-paragraph-wrapper">
            <div>{text}</div>
        </div>
    );
};
function transformText(text) {
    const boldRegex = /\*\*([^*]+)\*\*/g;
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
  
    let result = [];
    
    let lastEnd = 0;
  
    let match;
    while (match = boldRegex.exec(text)) {
      result.push(text.substring(lastEnd, match.index));
  
      result.push(<span className="bold" key={`bold-${match.index}`}>{match[1]}</span>);
  
      lastEnd = match.index + match[0].length;
    }
    result.push(text.substring(lastEnd));
  
    return result.map(part => {
      if (typeof part === 'string') {
        let components = [];
        let lastEnd = 0;
  
        while (match = linkRegex.exec(part)) {
          components.push(part.substring(lastEnd, match.index));
  
          components.push(
            <a className="lesson-link" href={match[2]} key={`link-${match.index}`} target="_blank" rel="noopener noreferrer">{match[1]}</a>
          );
  
          lastEnd = match.index + match[0].length;
        }
        components.push(part.substring(lastEnd));
  
        return components;
      }
  
      return part;  
    });
  }
  
  function StyledParagraph({ text }) {
    const lines = text.split('\n');
  
    return (
      <div>
        {lines.map((line, index) => {
          if (line.trim().startsWith('•')) {
            return (
              <li className="list-item" key={index}>
                {transformText(line.trim().slice(1).trim())}
              </li>
            );
          } else {
            return (
              <p key={index}>
                {transformText(line)}
              </p>
            );
          }
        })}
      </div>
    );
  }
  

  const Factoid = ({ icon, title, text, color }) => {
    const lines = text.split('\n');

    return (
        <div className={`lesson-factoid-wrapper flex flex-col factoid-${color}`}>            
        <div className="lesson-factoid-header flex flex-row">
                <div className="lesson-factoid-icon">{icon}</div>
                <div className="lesson-factoid-title">{title}</div>
            </div>
            <div className="lesson-factoid-content">
                {lines.map((line, index) => {
            if (line.trim().startsWith('•')) {
                return (
                <li className="list-item" key={index}>
                    {transformText(line.trim().slice(1).trim())}
                </li>
                );
            } else {
                return (
                <p key={index}>
                    {transformText(line)}
                </p>
                );
            }
            })}
        </div>
        </div>
    );
};

export {MultipleImage, Image, Table, Paragraph, Factoid, StyledParagraph}